import { WobbleCard } from "./ui/wobble-card";
import { StickyScroll } from "./ui/sticky-scroll-reveal";
import { IoIosArrowForward } from "react-icons/io";
import { products } from "data/products";
import { useTranslation } from "react-i18next";
import React, { ElementType } from "react";
import { cn } from "utils/cn";
import { PinContainer } from "./ui/3d-pin";

interface platformTabProps {
    scroolPreviewDiv?: {
        title: string;
        description: string;
        content?: React.ReactNode | any;
    }[];
    advantages?: {
        icon: ElementType,
        title: string,
        description: string
    }[];
    cards?: {
        img?: {
            src: string,
            className?: string
        },
        title: string,
        description: string
        btnTo?: {
            href: string,
            title: string,
            target?: boolean
        }
    }[];
    pins?: {
        img: {
            src: string,
            className?: string
        },
        onHoverTitle: string,
        description: string
        titleCard: string
        btnTo: string
    }[];
    i18nPosition: number
    descriptionWordList?: string[]
    description?: boolean
    key: number
}

export default function PlatformTab(props: platformTabProps) {
    const { t } = useTranslation();

    function highlightWhiteList(sentence: string[], whiteList: string[]) {

        return sentence.map((word, index) => {
            if (whiteList.includes(word)) {
                return (
                    <span key={index} className="text-rootkey-light_blue capitalize lg:mb-4">
                        {word}
                    </span>
                );
            }
            return <span key={index} className="lg:mb-4 text-white/90">{word}</span>;
        });
    }

    function getDescriptionCode() {

        const sentence: string[] | null = t(products[props.i18nPosition].desc).match(/\b\w+\b/g)

        return (
            <h3 className="font-normal text-left text-2xl text-balance flex items-start justify-start flex-wrap gap-2 w-full lg:w-3/4 whitespace-normal mb-12">
                {sentence && highlightWhiteList(sentence, props.descriptionWordList || [])}
            </h3>
        )
    }

    const boxesBackgrounds: string[] = [
        //"bg-rootkey-red/80",
        "bg-transparent",
        "bg-transparent",
        "bg-transparent",
        "bg-transparent",
        "bg-transparent",
        //"bg-rootkey-dark_blue/80",
        //"bg-rootkey-red/80",
        "bg-transparent",
        "bg-transparent",
        "bg-transparent",
        //"bg-rootkey-dark_blue/80",
    ]

    return (

        <div className="container">
            <div className="text-balance mt-8 mb-7 w-full flex flex-col gap-2 lg:gap-0 lg:w-3/4">
                <h2 className='text-rootkey-light_blue' >{t(products[props.i18nPosition].title)}</h2>
                <h1 className='font-bold' >{t(products[props.i18nPosition].slogan)}</h1>
                {window.innerWidth < 1200 && <h4 className='font-light text-xs text-white/50' >{t(products[props.i18nPosition].desc)}</h4>}
            </div>

            {props.description && window.innerWidth > 1200 && getDescriptionCode()}

            {props.advantages && <div key={props.key} className="grid grid-cols-1 lg:grid-cols-3 lg:gap-10 mx-auto w-full lg:px-10 items-start justify-start">
                {props.advantages.map((adv, index) => (
                    <WobbleCard key={index} className="py-6 lg:py-20" containerClassName="col-span-1 lg:min-h-[100px]">
                        <div className="w-full h-full flex flex-col items-center justify-center text-center ">
                            <adv.icon className="w-8 h-auto fill-rootkey-light_blue" />
                            <h2 className="max-w-80 mt-4  text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em] text-white"> {adv.title}</h2>
                            <p className="mt-4 max-w-[26rem] text-justify lg:text-center lg:text-base/6 text-neutral-200">{adv.description}</p>
                        </div>
                    </WobbleCard>
                ))}
            </div>}



            {props.pins && <div key={props.key} className="grid grid-cols-1 lg:grid-cols-3 gap-0 lg:gap-10 mx-auto w-full py-20">
                {props.pins.map((data, index) => (<PinContainer key={index}
                    title={data.onHoverTitle}
                    href={data.btnTo}
                >
                    <div className="flex basis-full flex-col p-4 tracking-tight text-slate-100/50 sm:basis-1/2 w-[15rem] h-[15rem] lg:w-[20rem] lg:h-[20rem] ">
                        <h3 className="max-w-xs !pb-2 !m-0 font-bold  text-base text-slate-100">
                            {data.titleCard}
                        </h3>
                        <div className="text-base !m-0 !p-0 font-normal">
                            <span className="text-slate-500 ">
                                {data.description}
                            </span>
                        </div>
                        <img src={data.img?.src} alt="" className={cn("flex w-full h-fit object-cover rounded-lg mt-auto mb-0", data.img?.className)} />
                    </div>
                </PinContainer>))}
            </div>}

            {props.scroolPreviewDiv && <StickyScroll key={props.key} content={props.scroolPreviewDiv} />}

            {props.cards && <div key={props.key} className="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full mt-4 lg:mt-32">
                {props.cards.map((data, index) => (<WobbleCard
                    className={cn("py-6 lg:py-20")}
                    boxClassName={`${index % 4 === 0 || index % 4 === 3 ? 'border-2 border-[#9091a029]' : window.innerWidth < 1200 ? 'border-2 border-[#9091a029]' : ''}`}
                    key={index}
                    containerClassName={cn(`col-span-1 ${index % 4 === 0 || index % 4 === 3 ? 'lg:col-span-2 ' : 'lg:col-span-1'} h-full ${boxesBackgrounds[index]} min-h-[100px] lg:min-h-[100px]`,
                        window.innerWidth < 1200 && data.title === '' ? "hidden" : "unset"
                    )}
                >
                    <div className="max-w-xs">
                        <h2 className="text-left text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em] text-white"> {data.title} </h2>
                        <p className="mt-4 text-left  text-base/6 text-neutral-200"> {data.description} </p>
                        {data.btnTo && <a href={data.btnTo.href} target={data.btnTo.target ? "_blank" : ""} rel="noreferrer" className={"text-sm mt-10 flex gap-2 items-center justify-center w-fit rounded-lg"}>{data.btnTo.title} <IoIosArrowForward /> </a>}
                    </div>
                    {data.img && window.innerWidth > 1200 && <img
                        src={data.img.src}
                        width={500}
                        height={500}
                        alt={data.title}
                        className={cn("absolute -right-4 lg:-right-[40%] grayscale filter -bottom-10 object-contain rounded-2xl", data.img.className)}
                    />}
                </WobbleCard>)
                )}
            </div>}
        </div>
    )
}