import { BsLayoutSidebarInsetReverse } from "react-icons/bs";
import { BsLayoutSidebarInset } from "react-icons/bs";
import React, { useEffect, useState } from 'react';
import './styles/navBarTlm.css';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { abasInfo } from "data/navBar";
import { Link } from "react-router-dom";
import LogoROOTKey from "components/LogoROOTKey";
import { contacts } from "data/consts";
import { isURL } from "hooks/useDocumentVar";

export default function NavBarTlm() {
    const { t } = useTranslation();
    const [isOpen, setisOpen] = useState<boolean>(false)

    useEffect(() => {
        AOS.init({ duration: 1500 });
    }, [])

    return (<aside className={`SideBar ${isOpen ? "open" : ''}`}>
        <BsLayoutSidebarInset className="slider" size={40}
            onClick={() => setisOpen(!isOpen)}
            onDrag={() => setisOpen(!isOpen)}
        />
        <div className="main">
            <div className="top">
                {isOpen ? <BsLayoutSidebarInsetReverse className="slider"
                    onClick={() => setisOpen(!isOpen)}
                    onDrag={() => setisOpen(!isOpen)}
                /> : ''}
                <LogoROOTKey href="/" onClick={() => setisOpen(!isOpen)} />
            </div>
            {abasInfo.map((data, index) => (
                <section key={index}>
                    <Link
                        className="head"
                        onClick={() => setisOpen(false)}
                        to={data.urlMain}
                        target={isURL(data.urlMain) ? "_blank" : ''}
                        rel="noreferrer" >
                        {t(data.title)}
                    </Link>
                    <ul>
                        {data.links.map((link, index) => (
                            <Link
                                onClick={() => setisOpen(false)}
                                className="option"
                                key={index}
                                to={link.linkTo}
                                target={isURL(link.linkTo) ? "_blank" : ''}
                            >
                                {t(link.title)}
                            </Link>
                        ))}


                    </ul>

                </section>
            ))}
            <Link onClick={() => setisOpen(!isOpen)} to={contacts.demo} target="_blank" rel="noreferrer" className="head">Log in</Link>
            <Link onClick={() => setisOpen(!isOpen)} to="/contact" className="head">Contact Us</Link>

        </div>

    </aside>)
}