import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDocTitle } from '../../hooks/useDocumentVar';
import PT from '../../i18n/pt/privacyPolicy.pt.json'
import GB from '../../i18n/en/privacyPolicy.en.json'
import {Hero} from 'components/Hero';
import LegalComponent from 'components/LegalComponent/legalComponent';


export default function PrivacyTerms() {
    const { t, i18n } = useTranslation();
    useDocTitle(t('privacyPolicy.title') + " - ROOTKey")
    var privacyTermsArray

    if (i18n.language === "en") {
        privacyTermsArray = GB
    } else {
        privacyTermsArray = PT
    }

    return (
        <main id='PrivacyTerms'>
            <Hero.Root type='simple'>
                <Hero.Subtitle fade={'fade-right'} text='privacyPolicy.title' />
                <Hero.Title fade={'fade-right'}text='Legal.Privacy.description' />
                <Hero.Img fade={null} imgUrl='https://media.notcybersec.com/website/Legal/privacy.jpg' />
            </Hero.Root>
            <LegalComponent legalPar={privacyTermsArray.privacyPolicy.array} />
        </main>
    )

}