import HeroRoot from "./heroRoot";
import HeroTyping from "./heroTypingComponent";
import HeroSubTitle from "./HeroSubtitle";
import HeroImgSvg from "./heroImgSvg";
import HeroTags from "./heroTags";
import HeroText from "components/Hero/HeroText";
import HeroImg from "./HeroImg";
import HeroVideo from "./HeroVideo";
import HeroBtn from "./HeroBtn";
import HeroTitle from "./HeroTitle";

export const Hero = {
    Root: HeroRoot, 
    Subtitle:HeroSubTitle,
    Typing:HeroTyping,
    Title: HeroTitle,
    Text:HeroText,
    Img:HeroImg,
    Video:HeroVideo,
    Button:HeroBtn,
    SVG:HeroImgSvg,
    Tags:HeroTags,
}