export const partners = [
    {
        alt: "PolygonID",
        img: 'https://media.notcybersec.com/website/Company/partners/polygonid.svg',
        url: "https://polygon.technology/polygon-id"
    },
    {
        alt: "Microsoft For StartUps",
        img: 'https://media.notcybersec.com/website/Company/partners/MS_Startups_Celebration_Badge_Dark.png',
        url: 'https://www.microsoft.com/en-us/startups'
    },
    {
        alt: "INCM",
        img: 'https://media.notcybersec.com/website/Company/partners/INCM_versao branco.png',
        url: 'https://incm.pt/'
    },
    {
        alt: "Powered by AWS Cloud Computing",
        img: 'https://d0.awsstatic.com/logos/powered-by-aws-white.png',
        url: 'https://aws.amazon.com/what-is-cloud-computing'
    },
    {
        alt: "Invest2030",
        img: 'https://invest2030.pt/assets/logo_branco.png',
        url: 'https://invest2030.pt'
    },
    {
        alt: "Grupo Investe",
        img: 'https://media.notcybersec.com/website/Company/partners/Logo-Horizontal.png',
        url: 'https://grupoinveste.pt'
    },
    {
        alt: "BlendByte",
        img: 'https://media.notcybersec.com/website/Company/partners/blendbyte.svg',
        url: "https://blendbyte.pt/"
    }
]