import React, { FormEvent, useEffect, useState } from 'react';
import './styles.css';
import { AiFillTwitterCircle, AiFillGithub, AiFillLinkedin, AiOutlineInstagram, AiOutlineSend, AiFillYoutube, AiOutlineWhatsApp } from 'react-icons/ai'
import { api } from '../../services/api';
import { useTranslation } from 'react-i18next';
import PopUp from 'components/PopUp';
import { contacts, social } from '../../data/consts'

export default function Footer() {
	const { t, i18n } = useTranslation();

	const [email, setEmail] = useState<string>('');
	const [popUpAnimation, setPopUpAnimaton] = useState<boolean>(false)
	const [popUpForm, setPopUpForm] = useState({
		error: false,
		timer: 6000,
		mensage: 'PopUpMessages.Footer.Sucess.message',
		title: 'PopUpMessages.Footer.Sucess.title',
	});

	async function handleSubscription(event: FormEvent) {
		event.preventDefault();

		if (email === '' || email === undefined) {
			return;
		}

		try {
			await api.post('/newsletter', { email });

			setPopUpAnimaton(true);
			setEmail('');

		} catch (error) {
			setPopUpForm({
				error: true,
				timer: 6000,
				mensage: "PopUpMessages.Footer.Fail.message",
				title: "PopUpMessages.Footer.Fail.title",
			})
			setPopUpAnimaton(true)
		}
	}

	function languageHandler(language: string) {
		localStorage.setItem('language', language);
		i18n.changeLanguage(language)
	}

	useEffect(() => {
		if (localStorage.getItem('language') != null) {
			i18n.changeLanguage(localStorage.getItem('language')?.toString());
		}
	}, [i18n])

	return (
		<footer id='Footer'>
			<PopUp
				show={popUpAnimation}
				timer={popUpForm.timer}
				closeAnimation={() => { setPopUpAnimaton(!popUpAnimation) }}
				title={popUpForm.title}
				message={popUpForm.mensage}
				error={popUpForm.error}
			/>

			<div className='line'>
				<div className='colum'>
					<h1>{t('Footer.pages')}</h1>
					<a rel='noreferrer' href="/">{t('Footer.home')}</a>
					<a rel='noreferrer' href="/company">{t('Footer.Company')}</a>
					<a rel='noreferrer' href="/legal">{t('Footer.Legal')}</a>

					<h1>{t('Footer.Language')}</h1>
					<select id="language" name="language" onChange={(event) => { languageHandler(event.target.value) }}>
						<option value="" >{t('Footer.Language')}</option>
						<option value="en">English</option>
						<option value="pt">Português (PT)</option>
					</select>
				</div>
				<div className='colum'>
					<h1>{t('Footer.products')}</h1>
					<a rel='noreferrer' href="/platform">{t('Footer.platform')}</a>
					<a rel='noreferrer' target="_blank" href={contacts.demo}>{t('Footer.Demo')}</a>
					<h1>{t('Footer.resources')}</h1>
					<a rel='noreferrer' target="_blank" href={contacts.blog}>Blog</a>
					<a rel='noreferrer' href={'/careers'}>{t('Footer.careers')}</a>
					<a rel='noreferrer' href={'/mediakit'}>{t('Footer.mediaKit')}</a>
					<a rel='noreferrer' target="_blank" href={contacts.APIdocs}>{t('Footer.Docs')}</a>
				</div>
				<div className='colum'>
					<h1>{t('Footer.contactUs')}</h1>
					<a href="/contact">{t('Footer.contactUs')}</a>
					<a rel='noreferrer' target="_blank" href={`mailto: ${contacts.emailSupport}`}>{contacts.emailSupport}</a>
					<a rel='noreferrer' target="_blank" href={contacts.linkGoogleMaps}>{contacts.address}</a>
					<a rel='noreferrer' target="_blank" href={`tel:${contacts.tlm}`}>{contacts.tlm} <span>{t('Footer.redeFix')}</span></a>
				</div>

				<div className='colum'>
					<h1>{t('Footer.signUp')}</h1>

					<form onSubmit={handleSubscription}>
						<input type='email' placeholder={t('Footer.newsletterPlaceholder')} required onChange={event => setEmail(event.target.value)} value={email} />
						<button type='submit'><AiOutlineSend /></button>
					</form>
					<span>{t('Footer.newsletter')}</span>
					<h1>{t('Footer.FollowUs')}</h1>
					<div className='media'>
						<a className='wpp' rel='noreferrer' href={social.whatsapp} target="_blank" ><AiOutlineWhatsApp className='icon' /></a>
						<a className='linkedin' rel='noreferrer' href={social.linkedin} target="_blank" ><AiFillLinkedin className='icon' /></a>
						<a className='instagram' rel='noreferrer' href={social.instagram} target="_blank" ><AiOutlineInstagram className='icon' /></a>
						<a className='twitter' rel='noreferrer' href={social.twitter} target="_blank" ><AiFillTwitterCircle className='icon' /></a>
						<a className='github' rel='noreferrer' href={social.github} target="_blank" ><AiFillGithub className='icon' /></a>
						<a className='linktr' rel='noreferrer' href={social.linktr} target="_blank" >
							<svg className='icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
								<path className='icon-1' d="M18.89,32H21v8c0,1.1-0.9,2-2,2h-4c-1.1,0-2-0.9-2-2v-8H4.89c-1.52,0-2.48-1.64-1.75-2.97 L15.25,7.16c0.76-1.37,2.74-1.37,3.5,0l4.85,9.74l-6.46,12.13C16.41,30.36,17.37,32,18.89,32z" />
								<path className='icon-2' d="M43.11,32H35v8c0,1.1-0.9,2-2,2h-4c-1.1,0-2-0.9-2-2v-8h2.11c1.52,0,2.48-1.64,1.75-2.97L24.3,16.9 l4.95-9.74c0.76-1.37,2.74-1.37,3.5,0l12.11,21.87C45.59,30.36,44.63,32,43.11,32z" />
							</svg>
						</a>
						<a className='youtube' rel='noreferrer' href={social.youtube} target="_blank" ><AiFillYoutube className='icon' /></a>
					</div>
				</div>
			</div>
			<div className='trade' >
				<span >{t('Footer.websiteRights')}</span>
				<div> <a href="/legal">{t('Footer.Legal')}</a> | <a href="/legal/terms-conditions">{t('Footer.Terms')}</a></div>
			</div>
		</footer>
	)
} 
