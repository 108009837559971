import { Job } from "context/jobContext";

export const opportunities: Job[] = [
    {
        title: "Backend developer",
        department: ["Tech", "R&D"],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Develop and maintain backend systems",
            "Collaborate with cross-functional teams",
            "Optimize the performance and scalability of services",
            "Ensure code quality and performance",
        ],
        qualifications: [
            "Bachelor's degree in Computer Science or related field",
            "Experience with backend development",
            "Strong knowledge of programming languages such as Node.js, Java or Python",
            "Understanding of databases and cloud services",
        ],
        additionalInformation: [
            "Knowing how to speak English"
        ]
    },
    {
        title: "Blockchain Developer",
        department: ["Tech", "Blockchain"],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Develop and maintain blockchain-based solutions",
            "Implement and test smart contracts",
            "Collaborate with cross-functional blockchain teams",
        ],
        qualifications: [
            "Experience in blockchain development",
            "Solid knowledge of blockchain technologies and platforms",
            "Proficiency in programming languages like Solidity or Rust",
        ],
        additionalInformation: [
            "Knowing how to speak English"
        ]
    },
    {
        title: "Software Engineer",
        department: ["Tech", "Development"],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Develop and maintain high-quality software",
            "Collaborate with engineering and design teams",
            "Participate in the entire software development lifecycle",
        ],
        qualifications: [
            "Bachelor's degree in Computer Science or related field",
            "Experience in software development",
            "Programming skills in various languages",
        ],
        additionalInformation: [
            "Knowing how to speak English"
        ]
    },
    {
        title: "Full-Stack Developer",
        department: ["Tech", "Development"],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Develop and maintain backend and frontend systems",
            "Collaborate with cross-functional teams",
            "Ensure code quality and performance",
        ],
        qualifications: [
            "Bachelor's degree in Computer Science or related field",
            "Experience in backend and frontend development",
            "Knowledge of programming languages like Java or Python",
        ],
        additionalInformation: [
            "Knowing how to speak English"
        ]
    },
    {
        title: "Data Scientist",
        department: ["Tech", "Data Science",],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Analyze and extract valuable insights from data",
            "Develop machine learning models",
            "Collaborate with data analysis teams",
        ],
        qualifications: [
            "Master's degree in Data Science or a related field",
            "Experience in data analysis and machine learning",
            "Knowledge of languages such as Python and R",
        ],
        additionalInformation: [
            "Knowing how to speak English"
        ]
    },
    {
        title: "DevOps Engineer",
        department: ["Tech", "Operations",],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Automate deployment and system management processes",
            "Ensure stability and security of infrastructures",
            "Collaborate with development and operations teams",
        ],
        qualifications: [
            "Experience in DevOps practices",
            "Knowledge of automation and orchestration tools",
            "Understanding of cloud environments",
        ],
        additionalInformation: [
            "Knowing how to speak English"
        ]
    },
    {
        title: "DevSecOps Engineer",
        department: ["Tech", "Security",],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Integrate security into the software development lifecycle",
            "Manage security and compliance tools",
            "Collaborate with development and security teams",
        ],
        qualifications: [
            "Experience in DevSecOps practices",
            "Knowledge of application security and compliance",
            "Familiarity with security tools",
        ],
        additionalInformation: [
            "Knowing how to speak English"
        ]
    },
    {
        title: "Software Security Analyst",
        department: ["Tech", "Security",],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Assess and test the security of applications",
            "Identify vulnerabilities and threats",
            "Collaborate with development teams for remediation",
        ],
        qualifications: [
            "Experience in application security",
            "Knowledge of penetration testing and security assessment",
            "Security certifications are an advantage",
        ],
        additionalInformation: [
            "Knowing how to speak English"
        ]
    },
    {
        title: "SOC Analyst",
        department: ["Tech", "Security",],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Monitor and respond to security incidents",
            "Perform threat analysis and identify anomalies",
            "Collaborate with the incident response team",
        ],
        qualifications: [
            "Experience in cybersecurity operations",
            "Knowledge of threat detection tools",
            "Security certifications are an advantage",
        ],
        additionalInformation: [
            "Knowing how to speak English"
        ]
    },
    {
        title: "Docker Engineer",
        department: ["Tech", "Containers",],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Manage and maintain Docker containers in production environments",
            "Automate deployment and scalability processes",
            "Collaborate with development and operations teams",
        ],
        qualifications: [
            "Experience in Docker container implementation and management",
            "Knowledge of container orchestration, such as Kubernetes",
            "Scripting and automation skills are an advantage",
        ],
        additionalInformation: [
            "Knowing how to speak English"
        ]
    },
    {
        title: "Cloud Architecture",
        department: ["Tech", "Architecture", "Cloud",],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Design scalable and secure cloud infrastructure solutions",
            "Collaborate with development teams to migrate and optimize cloud applications",
            "Ensure compliance with best cloud security practices",
        ],
        qualifications: [
            "Experience in designing and implementing cloud solutions",
            "Deep knowledge of cloud services like AWS, Azure, or GCP",
            "Cloud architecture certifications are an advantage",
        ],
        additionalInformation: [
            "Knowing how to speak English"
        ]
    },
    {
        title: "Product Manager",
        department: ["Product Management"],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Define product vision and strategy",
            "Collaborate with development and design teams",
            "Manage the product lifecycle from concept to launch",
        ],
        qualifications: [
            "Bachelor's degree in Computer Science or related field",
            "Experience in product management",
            "Leadership and communication skills",
            "Understanding of agile development methodologies",
        ],
        additionalInformation: [
            "Knowing how to speak English"
        ]
    },
    {
        title: "Data Engineer",
        department: ["Tech", "Data Engineering"],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Design, build, and maintain data pipelines",
            "Integrate data sources and storage systems",
            "Ensure data quality and integrity",
        ],
        qualifications: [
            "Bachelor's degree in Computer Science or related field",
            "Experience in data engineering",
            "Knowledge of ETL tools and databases",
            "Understanding of big data processing and data analysis",
        ],
        additionalInformation: [
            "Knowing how to speak English"
        ]
    },
    {
        title: "Investment Consultant",
        department: ["Business Development"],
        type: "On-site",
        time: "Full-Time",
        location: "Lisbon",
        responsibilities: [
            "Provide investment advice and recommendations to clients and the company",
            "Conduct financial analyses and risk assessments",
            "Stay updated on market trends and investment opportunities",
        ],
        qualifications: [
            "Relevant experience in investment consulting or financial advisory",
            "Strong knowledge of financial markets and investment products",
            "Excellent communication and interpersonal skills",
        ],
        additionalInformation: [
            "Knowing how to speak English"
        ]
    }
]
