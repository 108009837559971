export const contacts = {
    emailSupport :"contact@rootkey.ai",
    address : "Campo Grande 28 4ºB, 1700-093 Lisboa",
    tlm: "+351 217961493",
    linkGoogleMaps : "https://www.google.pt/maps/place/Campo+Grande+28,+1700-053+Lisboa/@38.7496476,-9.1502989,17z/data=!3m1!4b1!4m5!3m4!1s0xd193300da08ac9d:0xcc4ca529ccd9ae7!8m2!3d38.7496476!4d-9.1481102?shorturl=1",
    linkGoogleMapsMapa : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3111.612092943538!2d-9.150292684390843!3d38.74966286324341!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd193300da08ac9d%3A0xcc4ca529ccd9ae7!2sCampo%20Grande%2028%2C%201700-053%20Lisboa!5e0!3m2!1spt-PT!2spt!4v1658742483375!5m2!1spt-PT!2spt",
    demo: "https://www.app.rootkey.ai",
    platform: "https://www.app.rootkey.ai",
    APIdocs: "https://docs.rootkey.ai/",
    blog: "https://blog.rootkey.ai"
}

export const social = {
    linkedin: "https://www.linkedin.com/company/rootkey",
    instagram: "https://www.instagram.com/_rootkey/",
    facebook: "https://facebook.com/rootkey.0",
    telegram: "https://t.me/+CvFbwUZ4bRNmMzBk",
    youtube:"https://www.youtube.com/@_rootkey",
    crunchbase: "https://www.crunchbase.com/organization/rootkey",
    twitter:"https://twitter.com/__rootkey/",
    github:"https://github.com/ROOT-Key",
    linktr:"https://linktr.ee/_rootkey",
    whatsapp:"https://chat.whatsapp.com/IHM2Grue6sW4Wb6S8OLFNb"
}
