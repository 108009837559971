import CookiesPolicy from "pages/Legal-Pages/CookiesPolicy";
import PrivacyTerms from "pages/Legal-Pages/PrivacyTerms";
import { Route, BrowserRouter, Routes as Switch } from "react-router-dom";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import Company from "./pages/Company";
import Home from "./pages/Home";
import Platform from "./pages/Platform";
import Legal from "pages/Legal-Pages/Legal";
import UnSubscribe from "pages/Unsubscribe";
import Resources from "pages/Resources";
import Careers from "pages/Careers";
import Mediakit from "pages/Mediakit";
import JobForm from "pages/JobForm";
import ContactPage from "./pages/Contact";
import Cookies from "components/Cookies";
import DAMC from "pages/Products/damc";
import TermsAndConditions from "pages/Legal-Pages/TermsAndConditions";
import Disclamer from "pages/Legal-Pages/disclamer";

const Routes = () => {
    return (
        <BrowserRouter>
			<NavBar />
            <Cookies/>
            <Switch>
                <Route element={<Home />} path="/*" />
                <Route element={<Platform />} path="/platform/:product?" />
                <Route element={<ContactPage />} path="/contact" />
                <Route element={<Company />} path="/company" />
                <Route element={<PrivacyTerms/>} path={"/legal/privacy-policy"} />
                <Route element={<CookiesPolicy/>} path={"/legal/cookies-policy"} />
                <Route element={<TermsAndConditions/>} path={"/legal/terms-conditions"} />
                <Route element={<Disclamer/>} path={"/legal/disclamer"} />
                <Route element={<Legal/>} path={"/legal"} />
                <Route element={<UnSubscribe/>} path={"/unsubscribe/:contactId"} />
                <Route element={<Resources/>} path={"/resources"} />
                <Route element={<Careers/>} path={"/careers"} />
                <Route element={<Mediakit/>} path={"/mediakit"} />
                <Route element={<JobForm />} path={"/careers/apply"} />
                <Route element={<DAMC />} path={"/product/damc"} />
            </Switch>
            <Footer/>
        </BrowserRouter>
    );
}

export default Routes;