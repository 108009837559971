import { IoDocumentLock } from "react-icons/io5";
import { HiDocumentCheck } from "react-icons/hi2";
import { BiShield } from "react-icons/bi";
import { BiData } from "react-icons/bi";
import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Hero } from 'components/Hero';
import { useDocTitle } from '../../hooks/useDocumentVar';
import 'aos/dist/aos.css';
import './styles.css';
import CustomMetaTags from 'components/CustomMetaTags';
import { Tabs } from 'components/ui/tabs';
import { useContact } from "hooks/useContact";
import PopUp from "components/PopUp";
import PlatformTab from "components/PlatformTab";
import { contacts } from "data/consts";

export default function Platform() {

	const { t, i18n } = useTranslation();
	useDocTitle(t('Platform.pageTitle') + " - ROOTKey")
	const { changeContact, contact, sendEmail, newsletterSubscribe } = useContact();
	const [popUpAnimation, setPopUpAnimaton] = useState<boolean>(false)
	const [newsletterSubscription, setNewsletterSubscription] = useState<boolean>(false);
	const [msg, setMsg] = useState<string>('');
	const [key, setKey] = useState<number>(0);
	const [popUpForm, setPopUpForm] = useState({
		error: false,
		timer: 6000,
		mensage: 'PopUpMessages.ContactUs.Sucess.message',
		title: 'PopUpMessages.ContactUs.Sucess.title',
	})

	function handleSubmit(event: FormEvent) {
		event.preventDefault();

		try {
			sendEmail(contact)

			if (newsletterSubscription) { newsletterSubscribe(contact.email) }

			setPopUpForm({
				error: false,
				timer: 6000,
				mensage: 'PopUpMessages.ContactUs.Sucess.message',
				title: 'PopUpMessages.ContactUs.Sucess.title',
			})
			setPopUpAnimaton(true);


		} catch (error) {
			setPopUpForm({
				error: true,
				timer: 6000,
				mensage: 'PopUpMessages.ContactUs.Fail.message',
				title: 'PopUpMessages.ContactUs.Fail.title',
			})
			setPopUpAnimaton(true)
		}
	}

	useEffect(() => {
		console.log(i18n.language)
		setKey(prevKey => prevKey + 1);
	  }, [i18n.language]);
	return (
		<>
			<CustomMetaTags
				title="Products by ROOTKey - Secure Your Data"
				description="Explore ROOTKey's products for securing data integrity and privacy. Discover our cybersecurity and decentralized identity management solutions."
				keywords="ROOTKey products, data security, cybersecurity, decentralized identity management, data privacy, platform, idm, sds"
				ogTitle="Products by ROOTKey - Secure Your Data"
				ogDescription="Explore ROOTKey's products for securing data integrity and privacy. Discover our cybersecurity and decentralized identity management solutions."
				canonical="https://rootkey.ai/platform"
			/>
			<PopUp
				show={popUpAnimation}
				timer={popUpForm.timer}
				closeAnimation={() => { setPopUpAnimaton(!popUpAnimation) }}
				title={popUpForm.title}
				message={popUpForm.mensage}
				error={popUpForm.error}
			/>

			<main id='Platform'>

				<Hero.Root type='simple'>
					<Hero.Subtitle fade={'fade-right'} text='Platform.title' />
					<Hero.Title fade={'fade-right'} text='Platform.subTitle' />
					<Hero.Img fade={null} imgUrl='https://media.notcybersec.com/website/Company/heroCompany.png' />
				</Hero.Root>

				<Tabs
					key={key}
					tabs={[
						{
							title: "Platform",
							value: "1",
							content: <PlatformTab
								key={key}
								description
								i18nPosition={1}
								advantages={[
									{
										icon: BiShield,
										title: t('Platform.Products.Platform.advantages.0.title'),
										description: t('Platform.Products.Platform.advantages.0.description')
									},
									{
										icon: HiDocumentCheck,
										title: t('Platform.Products.Platform.advantages.1.title'),
										description: t('Platform.Products.Platform.advantages.1.description')
									},
									{
										icon: IoDocumentLock,
										title: t('Platform.Products.Platform.advantages.2.title'),
										description: t('Platform.Products.Platform.advantages.2.description')
									}
								]}
								cards={[
									{
										title: t('Platform.Products.Platform.cards.0.title'),
										description: t('Platform.Products.Platform.cards.0.description'),
										img: {
											src: "https://www.media.notcybersec.com/docs/platform-apikey-white.png"
										},
										btnTo: {
											href: `${contacts.platform}/sign-up`,
											title: t('Platform.Products.Platform.cards.0.btn'),
											target: true
										}
									},
									{
										title: "",
										description: ""
									},
									{
										title: t('Platform.Products.Platform.cards.1.title'),
										description: t('Platform.Products.Platform.cards.1.description'),
										btnTo: {
											href: `${contacts.APIdocs}/api-reference/platform/overview`,
											title: t('Platform.Products.Platform.cards.1.btn'),
											target: true
										}
									},
									{
										title: t('Platform.Products.Platform.cards.2.title'),
										description: t('Platform.Products.Platform.cards.2.description'),
										img: {
											src: "https://www.media.notcybersec.com/docs/platform-apikey-white.png"
										},
										btnTo: {
											href: "/contact",
											title: t('Platform.Products.Platform.cards.2.btn'),
											target: false
										}
									},
								]}
								pins={[
									{
										onHoverTitle: "app.rootkey.ai/sign-up",
										titleCard: t('Platform.Products.Platform.pins.0.title'),
										description: t('Platform.Products.Platform.pins.0.description'),
										btnTo: `${contacts.platform}/sign-up`,
										img: {
											src: "https://www.media.notcybersec.com/docs/register-black.png"
										}
									},
									{
										onHoverTitle: "app.rootkey.ai",
										titleCard: t('Platform.Products.Platform.pins.1.title'),
										description: t('Platform.Products.Platform.pins.1.description'),
										btnTo: `${contacts.platform}/sign-up`,
										img: {
											src: "https://www.media.notcybersec.com/docs/createDoc-black.png"
										}
									},
									{
										onHoverTitle: "app.rootkey.ai",
										titleCard: t('Platform.Products.Platform.pins.2.title'),
										description: t('Platform.Products.Platform.pins.2.description'),
										btnTo: `${contacts.platform}/sign-up`,
										img: {
											src: "https://www.media.notcybersec.com/docs/validate-black.png"
										}
									},
								]}
							/>

						},
						{
							title: "DAMC",
							value: "2",
							content: <PlatformTab
								key={key}
								i18nPosition={3}
								scroolPreviewDiv={[
									{
										title: t('Platform.Products.DAMC.scroolPreviewDiv.0.title'),
										description: t('Platform.Products.DAMC.scroolPreviewDiv.0.description'),
										content: (
											<div className="h-full w-full  flex items-center justify-center text-white">
												<img
													src="https://media.notcybersec.com/website/damc/damc-vault.png"
													className="w-full object-cover"
													alt="login platform "
												/>
											</div>
										),
									},
									{
										title: t('Platform.Products.DAMC.scroolPreviewDiv.1.title'),
										description: t('Platform.Products.DAMC.scroolPreviewDiv.1.description'),
										content: (
											<div className="h-full w-full  flex items-center justify-center text-white">
												<img
													src="https://media.notcybersec.com/website/damc/damc-create.png"
													className="w-full object-cover"
													alt="login platform "
												/>
											</div>
										),
									},
									{
										title: t('Platform.Products.DAMC.scroolPreviewDiv.2.title'),
										description: t('Platform.Products.DAMC.scroolPreviewDiv.2.description'),
										content: (
											<div className="h-full w-full  flex items-center justify-center text-white">
												<img
													src="https://media.notcybersec.com/website/damc/damc-dash.png"
													className="w-full object-cover"
													alt="login platform "
												/>
											</div>
										),
									},
									{
										title: t('Platform.Products.DAMC.scroolPreviewDiv.3.title'),
										description: t('Platform.Products.DAMC.scroolPreviewDiv.3.description'),
										content: (
											<div className="h-full w-full  flex items-center justify-center text-white">
												<img
													src="https://media.notcybersec.com/website/damc/damc.maintenance-list.png"
													className="w-full object-cover"
													alt="login platform "
												/>
											</div>
										),
									},
								]}
								advantages={[
									{
										icon: BiData,
										title: t('Platform.Products.DAMC.advantages.0.title'),
										description: t('Platform.Products.DAMC.advantages.0.description'),
									},
									{
										icon: BiData,
										title: t('Platform.Products.DAMC.advantages.1.title'),
										description: t('Platform.Products.DAMC.advantages.1.description'),
									},
									{
										icon: BiData,
										title: t('Platform.Products.DAMC.advantages.2.title'),
										description: t('Platform.Products.DAMC.advantages.2.description'),
									}
								]}
								cards={[
									{
										title: t('Platform.Products.DAMC.cards.0.title'),
										description: t('Platform.Products.DAMC.cards.0.description'),
										img: {
											src: "https://www.media.notcybersec.com/docs/platform-apikey-white.png"
										},
										btnTo: {
											href: `/contact`,
											title: t('Platform.Products.DAMC.cards.0.btn'),
											target: false
										}
									},
									{
										title: "For Devs",
										description: "Helps businesses maintain compliance with security and privacy regulations, mitigating legal risks.",
										btnTo: {
											href: `${contacts.APIdocs}/api-reference/damc/overview`,
											title: "Go to the documentation",
											target: true
										}
									},
								]}
							/>,
						},
						{
							title: "IDM",
							value: "4",
							notReady: {
								title: "comming soon",
								color: "pink"
							}
						},
						{
							title: "SDS",
							value: "3",
							notReady: {
								title: "beta",
								color: "pink"
							}

						},
					]}

					containerClassName='tabs'
					i18nIsDynamicList
				/>

				<form onSubmit={handleSubmit}>
					<div className='formHhead'>
						<h3>{t("Platform.Form.title")} </h3>
						<p>{t("Contact.formHero.description")} </p>
					</div>
					<div className='flex'>
						<div className='column'>
							<label htmlFor='firstName'>{t('Contact.firstName')} *</label>
							<input id='firstName' name='firstName' type="text" required placeholder={t('Contact.firstName')} onChange={event => changeContact({ ...contact, firstName: event.target.value })} value={contact.firstName} />
						</div>
						<div className='column'>
							<label htmlFor='lastName'>{t('Contact.lastName')} *</label>
							<input id='lastName' name='lastName' type="text" required placeholder={t('Contact.lastName')} onChange={event => changeContact({ ...contact, lastName: event.target.value })} value={contact.lastName} />
						</div>
					</div>
					<div className='column'>
						<label htmlFor='Email'>{t('Contact.email')} *</label>
						<input id='Email' name='Email' type="mail" required placeholder={t('Contact.email')} onChange={event => changeContact({ ...contact, email: event.target.value })} value={contact.email} />
					</div>
					<div className='column'>
						<label htmlFor='message'>{t('Contact.message')} *</label>
						<textarea name="message" id="message" rows={6} required onChange={event => { setMsg(event.target.value); changeContact({ ...contact, message: event.target.value }) }} value={msg} />
					</div>
					<div className='column'>
						<div className='flex switch'>
							<label className="switch">
								<input type="checkbox" required />
								<span className="sliderComp"></span>
							</label>
							<span>{t('Contact.data')}<a href="/legal">{t('cookie.legal')}</a></span>
						</div>

						<div className='flex switch'>
							<label className="switch">
								<input type="checkbox" onChange={(event) => setNewsletterSubscription(event.target.checked)} checked={newsletterSubscription} />
								<span className="sliderComp"></span>
							</label>
							<span>{t('Contact.newsletter')}</span>
						</div>
						<button type='submit' className='submit'>{t('Contact.submit')}</button>
					</div>
				</form>
			</main>
		</>
	)
} 
