import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { AOSInterface } from './HeroBtn';
import { cn } from 'utils/cn';

interface HeroVideoProps extends AOSInterface {
    videoUrl: string
    className?: string
}
export default function HeroVideo(props: HeroVideoProps) {

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, [])


    return (
        <video
            className={cn('center', props.className)}
            data-aos={props.fade || "fade-up"}
            autoPlay
            muted
            loop >
            <source src={props.videoUrl} type="video/mp4" />
        </video>
    )
} 
