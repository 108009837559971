import React from 'react';
import './styles/navBarBox.css';
import 'aos/dist/aos.css';
import { ReactNode } from 'react';

interface navBarBoxProps {
    children?: ReactNode
    title: string
    contact?: boolean
}
export default function NavBarBox(props: navBarBoxProps) {
    return (

        <div id="navBarBox">
            <div className='title'>
                <h1>{props.title}</h1>
            </div>
            <div className='btns' >
                {props.children}
            </div>
        </div>
    )
}