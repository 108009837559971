import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLocales } from 'react-native-localize';

import otherEn from './en/en.json'
import navBarEn from './en/navBar.en.json'
import homeEn from './en/home.en.json'
import platformEn from './en/platform.en.json'
import legalEn from './en/legal.en.json'
import footerEn from './en/footer.en.json'
import contactEn from './en/contact.en.json'
import popUpEn from './en/popUp.en.json'
import privacyPolicyEn from './en/privacyPolicy.en.json'
import companyEn from './en/company.en.json'
import cookiesPolicyEn from './en/cookiesPolicy.en.json'
import disclamerEn from './en/disclamer.en.json'
import termsEn from './en/terms.en.json'
import faqEn from './en/faq.en.json'
import resourcesEn from './en/resources.en.json'
import careersEn from './en/careers.en.json'
import mediakitEn from './en/mediakit.en.json'
import botEn from './en/bot.en.json'
import damcEn from './en/damc.en.json'


import otherPt from './pt/pt.json'
import navBarPt from './pt/navBar.pt.json'
import homePt from './pt/home.pt.json'
import companyPt from './pt/company.pt.json'
import platformPt from './pt/platform.pt.json'
import contactPt from './pt/contact.pt.json'
import footerPt from './pt/footer.pt.json'
import legalPt from './pt/legal.pt.json'
import PopUpPt from './pt/popUp.pt.json'
import privacyPolicyPt from './pt/privacyPolicy.pt.json'
import cookiesPt from './pt/cookiesPolicy.pt.json'
import disclamerPt from './pt/disclamer.pt.json'
import termsPt from './pt/terms.pt.json'
import faqPt from './pt/faq.pt.json'
import resourcesPt from './pt/resources.pt.json'
import careersPt from './pt/careers.pt.json'
import mediakitPt from './pt/mediakit.pt.json'
import botPt from './pt/bot.pt.json'
import damcPt from './pt/damc.pt.json'


i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        ...otherEn,
        ...navBarEn,
        ...homeEn,
        ...companyEn,
        ...platformEn,
        ...legalEn,
        ...footerEn,
        ...contactEn,
        ...popUpEn,
        ...privacyPolicyEn,
        ...cookiesPolicyEn,
        ...disclamerEn,
        ...termsEn,
        ...faqEn,
        ...resourcesEn,
        ...careersEn,
        ...mediakitEn,
        ...botEn,
        ...damcEn,
      }
    },
    pt: {
      translation: {
        ...otherPt,
        ...navBarPt,
        ...homePt,
        ...companyPt,
        ...platformPt,
        ...contactPt,
        ...footerPt,
        ...legalPt,
        ...PopUpPt,
        ...privacyPolicyPt,
        ...cookiesPt,
        ...disclamerPt,
        ...termsPt,
        ...faqPt,
        ...resourcesPt,
        ...careersPt,
        ...mediakitPt,
        ...botPt,
        ...damcPt
      }
    },
  },
  lng: getLocales()[0].languageCode,
  fallbackLng: 'en',
  compatibilityJSON: 'v3',
});

export default i18n;