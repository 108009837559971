import React, { AnchorHTMLAttributes, ElementType, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './styles.css'
import { isURL } from 'hooks/useDocumentVar';
import { BsArrowRight } from 'react-icons/bs';
import { AOSInterface } from 'components/Hero/HeroBtn';
import { cn } from 'utils/cn';

export interface BasicBtnProps extends AOSInterface, AnchorHTMLAttributes<HTMLAnchorElement> {
    href: string
    text: string
    arrow?: boolean
    icon?: ElementType
}
export default function BasicBtn(props: BasicBtnProps) {
    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, [])


    return (
        <Link data-aos={props.fade || props.fade === null ? '' : "fade-up"}
            className={cn('basicBtn', props.className)}
            target={isURL(props.href) ? "_blank" : ''}
            to={props.href}
            {...props}>
            {props.icon ? <props.icon /> : null}
            {t(props.text)}
            {props.arrow ? <BsArrowRight className='arrow' /> : null}
        </Link>
    )
} 
