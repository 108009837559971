import React from 'react';
import './styles.css'
import { Link } from 'react-router-dom';
import { isURL } from 'hooks/useDocumentVar';
interface LogoProps {
    href?: string
    onlyName?: boolean
    only?: boolean
    onClick? : () => void;
}
export default function LogoROOTKey(props: LogoProps) {

    function chooseLogo() {

        if (props.only) {
            return (
                <>
                    <path className='LogoROOTKey icon' d="M27.51,26.26V24.13l3.19,1.39V21.24l-3.19-1.32-2.9-1.21V25h0l-.67-.3-1.73-.76v-6.2l-.74-.31-2-.84v6.12l-4.09-1.81v4l4.09,1.88v3.44l-4.09-2v4l4.09,2v6.17l2.77,1.45v-6.2l2.4,1.19v6.31l2.9,1.52V38.3l3.19,1.57V35.6l-3.19-1.52h0v-3.6h0l3.19,1.47V27.67l-3.19-1.41Zm-2.9,2.87v3.52l-.35-.17-2.05-1V28l1.73.8.67.3Z" />
                    <path className='LogoROOTKey icon' d="M32.05,35l-1.35.63v4.27a1.3,1.3,0,0,1,.71.05,1.54,1.54,0,0,1,.42.25A2.71,2.71,0,0,1,32.45,42v3.42l2.94-1.53V33.32Z" />
                    <path className='LogoROOTKey icon' d="M32.05,27.1l-1.35.57v4.27l1.35-.59,4.15-1.91a1.22,1.22,0,0,1,1.45.21,2.74,2.74,0,0,1,.6,1.82v11L41.05,41V23.11Z" />
                    <path className='LogoROOTKey icon' d="M32.05,20.71l-1.35.53v4.28L32.05,25l9.78-4.25a1.17,1.17,0,0,1,1.38.26,3,3,0,0,1,.57,1.8,6.32,6.32,0,0,1,0,1.16c0,1.41,0,2.82,0,4.23,0,3.8,0,7.59,0,11.38l2.66-1.4q0-3,0-6V14.72Q39.37,17.78,32.05,20.71Z" />
                </>
            )
        }

        if (props.onlyName) {
            return (
                <>
                    <path className='LogoROOTKey' d="M86.92,17.48v14.7H75.84a7.41,7.41,0,0,1,.69,3.08,3.06,3.06,0,0,0,3.07,3.07h7.32v4.28H79.6a7.37,7.37,0,0,1-7.35-7.35,3.07,3.07,0,0,0-3.07-3.08H66.07V42.61H61.8V24.83a7.36,7.36,0,0,1,7.35-7.35ZM66,27.91H82.61V21.75H69.11A3.08,3.08,0,0,0,66,24.83Z" />
                    <path className='LogoROOTKey' d="M117.24,17.54V35.3a7.35,7.35,0,0,1-7.35,7.35H92.13V24.87a7.34,7.34,0,0,1,7.35-7.33ZM96.34,35.29a3.08,3.08,0,0,0,3.08,3.07h10.41a3.08,3.08,0,0,0,3.08-3.07V24.87a3.08,3.08,0,0,0-3.08-3.06H99.42a3.08,3.08,0,0,0-3.08,3.06Z" />
                    <path className='LogoROOTKey' d="M147.6,17.54V35.3a7.35,7.35,0,0,1-7.35,7.35H122.49V24.87a7.35,7.35,0,0,1,7.35-7.33ZM126.71,35.29a3.07,3.07,0,0,0,3.07,3.07H140.2a3.08,3.08,0,0,0,3.07-3.07V24.87a3.07,3.07,0,0,0-3.07-3.06H129.78a3.07,3.07,0,0,0-3.07,3.06Z" />
                    <path className='LogoROOTKey' d="M176,17.54v4.27h-7.35a3.08,3.08,0,0,0-3.08,3.06V42.65h-4.27V21.81H150.92V17.54Z" />
                    <path className='LogoROOTKey' d="M204.71,17.48v14.7H193.64a7.42,7.42,0,0,1,.67,3.08,3.07,3.07,0,0,0,3.07,3.07h7.33v4.28h-7.33A7.37,7.37,0,0,1,190,35.26,3.07,3.07,0,0,0,187,32.18h-3.1V42.61h-4.28V17.48h4.28V27.91h13.5a3.08,3.08,0,0,0,3.06-3.08V17.48Z" />
                    <path className='LogoROOTKey' d="M235,17.54v4.27H217.26a3.06,3.06,0,0,0-3.06,3.06V28H235v4.27H214.2v3.07a3,3,0,0,0,.89,2.18,3.13,3.13,0,0,0,2.17.91H235v4.27H209.92V24.87a7.36,7.36,0,0,1,7.34-7.33Z" />
                    <path className='LogoROOTKey' d="M264.65,17.54V35.29a7.33,7.33,0,0,1-2.17,5.2,7.41,7.41,0,0,1-5.22,2.15H239.5V38.36h17.76a3.08,3.08,0,0,0,3.08-3.07V32.18H246.85a7.35,7.35,0,0,1-7.35-7.35V17.54h4.27v7.29a3.08,3.08,0,0,0,3.08,3.08h13.53V17.54Z" />
                </>
            )
        }

        return (
            <>
                <path className='LogoROOTKey' d="M86.92,17.48v14.7H75.84a7.41,7.41,0,0,1,.69,3.08,3.06,3.06,0,0,0,3.07,3.07h7.32v4.28H79.6a7.37,7.37,0,0,1-7.35-7.35,3.07,3.07,0,0,0-3.07-3.08H66.07V42.61H61.8V24.83a7.36,7.36,0,0,1,7.35-7.35ZM66,27.91H82.61V21.75H69.11A3.08,3.08,0,0,0,66,24.83Z" />
                <path className='LogoROOTKey' d="M117.24,17.54V35.3a7.35,7.35,0,0,1-7.35,7.35H92.13V24.87a7.34,7.34,0,0,1,7.35-7.33ZM96.34,35.29a3.08,3.08,0,0,0,3.08,3.07h10.41a3.08,3.08,0,0,0,3.08-3.07V24.87a3.08,3.08,0,0,0-3.08-3.06H99.42a3.08,3.08,0,0,0-3.08,3.06Z" />
                <path className='LogoROOTKey' d="M147.6,17.54V35.3a7.35,7.35,0,0,1-7.35,7.35H122.49V24.87a7.35,7.35,0,0,1,7.35-7.33ZM126.71,35.29a3.07,3.07,0,0,0,3.07,3.07H140.2a3.08,3.08,0,0,0,3.07-3.07V24.87a3.07,3.07,0,0,0-3.07-3.06H129.78a3.07,3.07,0,0,0-3.07,3.06Z" />
                <path className='LogoROOTKey' d="M176,17.54v4.27h-7.35a3.08,3.08,0,0,0-3.08,3.06V42.65h-4.27V21.81H150.92V17.54Z" />
                <path className='LogoROOTKey' d="M204.71,17.48v14.7H193.64a7.42,7.42,0,0,1,.67,3.08,3.07,3.07,0,0,0,3.07,3.07h7.33v4.28h-7.33A7.37,7.37,0,0,1,190,35.26,3.07,3.07,0,0,0,187,32.18h-3.1V42.61h-4.28V17.48h4.28V27.91h13.5a3.08,3.08,0,0,0,3.06-3.08V17.48Z" />
                <path className='LogoROOTKey' d="M235,17.54v4.27H217.26a3.06,3.06,0,0,0-3.06,3.06V28H235v4.27H214.2v3.07a3,3,0,0,0,.89,2.18,3.13,3.13,0,0,0,2.17.91H235v4.27H209.92V24.87a7.36,7.36,0,0,1,7.34-7.33Z" />
                <path className='LogoROOTKey' d="M264.65,17.54V35.29a7.33,7.33,0,0,1-2.17,5.2,7.41,7.41,0,0,1-5.22,2.15H239.5V38.36h17.76a3.08,3.08,0,0,0,3.08-3.07V32.18H246.85a7.35,7.35,0,0,1-7.35-7.35V17.54h4.27v7.29a3.08,3.08,0,0,0,3.08,3.08h13.53V17.54Z" />
                <path className='LogoROOTKey icon' d="M27.51,26.26V24.13l3.19,1.39V21.24l-3.19-1.32-2.9-1.21V25h0l-.67-.3-1.73-.76v-6.2l-.74-.31-2-.84v6.12l-4.09-1.81v4l4.09,1.88v3.44l-4.09-2v4l4.09,2v6.17l2.77,1.45v-6.2l2.4,1.19v6.31l2.9,1.52V38.3l3.19,1.57V35.6l-3.19-1.52h0v-3.6h0l3.19,1.47V27.67l-3.19-1.41Zm-2.9,2.87v3.52l-.35-.17-2.05-1V28l1.73.8.67.3Z" />
                <path className='LogoROOTKey icon' d="M32.05,35l-1.35.63v4.27a1.3,1.3,0,0,1,.71.05,1.54,1.54,0,0,1,.42.25A2.71,2.71,0,0,1,32.45,42v3.42l2.94-1.53V33.32Z" />
                <path className='LogoROOTKey icon' d="M32.05,27.1l-1.35.57v4.27l1.35-.59,4.15-1.91a1.22,1.22,0,0,1,1.45.21,2.74,2.74,0,0,1,.6,1.82v11L41.05,41V23.11Z" />
                <path className='LogoROOTKey icon' d="M32.05,20.71l-1.35.53v4.28L32.05,25l9.78-4.25a1.17,1.17,0,0,1,1.38.26,3,3,0,0,1,.57,1.8,6.32,6.32,0,0,1,0,1.16c0,1.41,0,2.82,0,4.23,0,3.8,0,7.59,0,11.38l2.66-1.4q0-3,0-6V14.72Q39.37,17.78,32.05,20.71Z" />
            </>
        )
    }

    return (
        <Link
            to={props.href || ''}
            target={isURL(props.href || '') ? '_blank' : ''}
            onClick={props.onClick}
            id='logoLink'
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 60" className='LogoROOTKey' >
                {chooseLogo()}
            </svg>
        </Link> 
    )
}
