import React, { useEffect } from 'react';

import './styles.css';
import { useTranslation } from 'react-i18next';
import { useDocTitle } from '../../hooks/useDocumentVar';
import {Hero} from 'components/Hero';
import { api } from 'services/api';
import { useParams } from 'react-router-dom';

export default function UnSubscribe() {

    const { t } = useTranslation();
    useDocTitle(t('UnSub.title') + " - ROOTKey")
    const { contactId } = useParams();

    async function handleUnsubscribeUserFromTag() {
        try {
            await api.delete(`/unsubscribe/${contactId}`);
        } catch (error) {
            //console.log(error);
        }
    }

    useEffect(() => {
        handleUnsubscribeUserFromTag();
    }, [])

    return (
        <main id='unsubscribe'>

            <Hero.Root type='simple'>
                <Hero.Subtitle fade={'fade-right'} text='UnSub.Hero.title' />
                <Hero.Title fade={'fade-right'}text='UnSub.Hero.description' />
                <Hero.Img fade={null} imgUrl='https://media.notcybersec.com/website/feedback.jpg' />
            </Hero.Root>

            <div className='unsub'>
                <h1>{t('UnSub.text.title')}</h1>
                <p>{t('UnSub.text.par1')}<br />
                    <br /> {t('UnSub.text.par2')} <br />
                    <br /> {t('UnSub.text.par3')} <br />
                    <br /> {t('UnSub.text.par4')}
                </p>
                <a href="/contact">{t('UnSub.contact')}</a>
            </div>
        </main>
    )
} 
