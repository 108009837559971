import React, { HTMLAttributes, ReactNode } from 'react';
import 'aos/dist/aos.css';
import './styles.css'
import Scrool from 'components/Animation/Scrool';
import SectionImg from 'components/Hero/HeroImg';
import SectionVideo from 'components/Hero/HeroVideo';
import HeroImgSvg from './heroImgSvg';
import { cn } from 'utils/cn';

interface HeroRootProps extends HTMLAttributes<HTMLDivElement> {
    type?: "simple" | "total"
    children: ReactNode
    scrool?: boolean
    classNameTitles?:string
}
export default function HeroRoot(props: HeroRootProps) {
    const assets: React.ReactNode[] = [];
    const components: React.ReactNode[] = [];

    React.Children.forEach(props.children, (child) => {
        if (React.isValidElement(child)) {
            if (child.type === SectionImg || child.type === SectionVideo || child.type === HeroImgSvg) {
                assets.push(child);
            } else {
                components.push(child);
            }
        }
    });

    return (
        <header {...props} className={cn('Hero', props.className)} >
            <div className={props.type || "total"}>
                {assets}
                <div className={props.classNameTitles || 'text'}>
                    {components}
                </div>
                {props.scrool ? <Scrool /> : ''}
            </div>
        </header>
    )
} 
