import React from 'react';
import { ReactElement } from "react";

interface HeroImgSvgProps {
    svg: ReactElement
	className?: string
}
export default function HeroImgSvg(props: HeroImgSvgProps) {

	return (<div className={props.className}>
		{props.svg}
	</div>)
} 
