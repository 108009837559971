import React from 'react';
import { TfiWorld } from "react-icons/tfi";
import { AiOutlineTeam } from "react-icons/ai";
import { FaDna } from "react-icons/fa";
import { BsRocketTakeoffFill } from "react-icons/bs";
import './styles.css'
import { useTranslation } from 'react-i18next';
import { Hero } from 'components/Hero';
import { Link } from "react-router-dom";
import { useJob } from "hooks/useJob";
import { opportunities } from "data/jobs";
import CustomMetaTags from 'components/CustomMetaTags';

export default function Careers() {
    const { t } = useTranslation();
    const { changePosition } = useJob();

    const handlePositionChange = (index: number) => {
        changePosition(opportunities[index]);
    };

    const adv = [
        {
            subtitle: "careers.adv._1.subtitle",
            title: "careers.adv._1.title",
            description: "careers.adv._1.description",
            icon: FaDna
        },
        {
            subtitle: "careers.adv._2.subtitle",
            title: "careers.adv._2.title",
            description: "careers.adv._2.description",
            icon: BsRocketTakeoffFill
        },
        {
            subtitle: "careers.adv._3.subtitle",
            title: "careers.adv._3.title",
            description: "careers.adv._3.description",
            icon: AiOutlineTeam
        },
        {
            subtitle: "careers.adv._4.subtitle",
            title: "careers.adv._4.title",
            description: "careers.adv._4.description",
            icon: TfiWorld
        }
    ]

    return (
        <>
            <CustomMetaTags
                title="Careers at ROOTKey - Join Our Team"
                description="Explore exciting career opportunities at ROOTKey. Join our team of experts and contribute to a safer digital future."
                keywords={`ROOTKey careers, job opportunities, join our team, digital security jobs, ${opportunities.map((data) => ` ${data.title}`)}`}
                ogTitle="Careers at ROOTKey - Join Our Team"
                ogDescription="Explore exciting career opportunities at ROOTKey. Join our team of experts and contribute to a safer digital future."
                canonical="https://rootkey.ai/careers"
            />
            <main id='Careers'>
                <Hero.Root type='simple' >
                    <span className='tag'>{t('careers.tag')}</span>
                    <Hero.Title fade={'fade-right'} text={t('careers.title')} />
                    <Hero.Subtitle fade={'fade-right'} text={t('careers.description')} />
                </Hero.Root>
                <div className='adv'>
                    <div className="stage" />
                    <div className="stage _2" />

                    {adv.map((data, index) => (
                        <div key={index} className="advantages">
                            <div className="wapper">
                                <div className="imgBg">
                                    <data.icon />
                                </div>
                                <div className="titles">
                                    <span>{t(data.subtitle)}</span>
                                    <h4>{t(data.title)}</h4>
                                </div>
                            </div>
                            <p>{t(data.description)}</p>
                        </div>
                    ))}
                </div>

                <div className="dump" id="jobs">
                    <h1>{t('careers.jobs.title')}</h1>
                    {opportunities.map((data, index) => (
                        <div className="job" key={index}>
                            <h5>{data.title}</h5>
                            {window.innerWidth > 1200 ?
                                <div className="department">
                                    {data.department.map((dep, i) => (
                                        <span key={i}>{dep}</span>
                                    ))}
                                </div>
                                : null
                            }
                            <h6>{data.type}</h6>
                            <Link to={'/careers/apply'} onClick={() => handlePositionChange(index)} type="button">Apply</Link>
                        </div>
                    ))}
                </div>
            </main>
        </>
    )

}