import React from 'react';

interface HeroTagsProps {
    tags: string[]
}
export default function HeroTags(props: HeroTagsProps) {

	return (<div className='tags'>
		{props.tags.map((data, index) => (
            <span key={index}>{data}</span>
        ))}
	</div>)
} 
