import { createContext, useState, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from 'services/api';

export interface Contact {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    companyName: string
    subject: string
    companySize: string
    message: string,
    category?: 'sales' | 'bot' | 'info' | 'help' | 'demo' | 'other' | 'job'
}

interface CookieProps {
    value: boolean | undefined
}

interface ContactProviderProps {
    children: ReactNode;
}

export const initialContact: Contact = {
    email: '',
    companyName: "",
    message: "",
    firstName: "",
    lastName: "",
    subject: "",
    phoneNumber: "",
    companySize: "",
}
export const initialCookie: CookieProps = {
    value: undefined
}

export const ContactContext = createContext({} as ContactContextData)

interface ContactContextData {
    contact: Contact
    cleanFields: () => void
    changeContact: (newContact: Contact) => void;
    sendEmail: (emailInfo: Contact, newsletter?: boolean) => void;
    newsletterSubscribe: (email: string) => void;
    cookies: CookieProps,
    setCookieHandler: (cookieValue: boolean) => void,
}

export function ContactProvider({ children }: ContactProviderProps) {

    const { t } = useTranslation();
    const [cookies, setCookies] = useState<CookieProps>(() => {
        const storedCookie = localStorage.getItem('@ROOT:data');
        return storedCookie ? JSON.parse(atob(storedCookie)) : initialCookie;
    });
    const [contact, setContact] = useState<Contact>(() => {
        const storedContact = localStorage.getItem('@ROOT:contact');
        return storedContact ? JSON.parse(atob(storedContact)) : initialContact;
    });


    function changeContact(newContact: Contact) { setContact(newContact); }
    function cleanFields() { setContact(initialContact); }
    function setCookieHandler(value: boolean) {
        setCookies({
            ...cookies,
            value: value
        })

        if (value === false && localStorage.getItem('@ROOT:contact')) {
            localStorage.removeItem('@ROOT:contact');
        }
    }

    const sendEmail = async (email: Contact, newsletter?: boolean) => {

        if (email.subject === '') {
            email.subject = t('Contact.Subject.Information')
        }


        if (email.category !== 'bot' && email.category !== 'job') {
            switch (email.subject) {
                case 'Sales':
                    email.category = 'sales'
                    break
                case 'Vendas':
                    email.category = 'sales'
                    break
                case 'Informações':
                    email.category = 'info'
                    break
                case 'Information':
                    email.category = 'info'
                    break
                case 'Help':
                    email.category = 'help'
                    break
                case'Ajuda':
                    email.category = 'help'
                    break
                case 'Demo':
                    email.category = 'demo'
                    break
                default:
                    email.category = 'other'
                    break
            }
        }

        try {
            await api.post('/contactUs', email);

            if (newsletter) {
                newsletterSubscribe(email.email)
            }
            setContact({
                ...contact,
                firstName: email.firstName,
                email: email.email,
            })
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.log(error)
            }
        }
    };

    const newsletterSubscribe = async (email: string) => {
        try {
            await api.post('/newsletter', email);
        } catch (error) {
            if (process.env.NODE_ENV === 'development') {
                console.log(error)
            }
        }
    }

    useEffect(() => {
        if (cookies.value !== false) {
            localStorage.setItem('@ROOT:contact', btoa(JSON.stringify(contact)));
        }
        localStorage.setItem('@ROOT:data', btoa(JSON.stringify(cookies)));
    }, [contact, cookies]);

    return (
        <ContactContext.Provider value={{
            contact,
            cleanFields,
            changeContact,
            sendEmail,
            newsletterSubscribe,
            cookies,
            setCookieHandler
        }}>
            {children}
        </ContactContext.Provider>
    )
}
