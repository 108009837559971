"use client";

import { ReactNode, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { cn } from "../../utils/cn";

type Tab = {
  title: string;
  value: string;
  content?: string | React.ReactNode | any;
  icon?: ReactNode | string
  notReady?: {
    title: string,
    color: 'red' | 'pink' | "green"
  }
};

export const Tabs = ({
  tabs: propTabs,
  containerClassName,
  activeTabClassName,
  tabClassName,
  contentClassName,
}: {
  tabs: Tab[];
  containerClassName?: string;
  activeTabClassName?: string;
  tabClassName?: string;
  contentClassName?: string;
}) => {
  const [active, setActive] = useState<Tab>(propTabs[0]);
  const [tabs, setTabs] = useState<Tab[]>(propTabs);

  const moveSelectedTabToTop = (idx: number) => {
    const newTabs = [...propTabs];
    const selectedTab = newTabs.splice(idx, 1);
    newTabs.unshift(selectedTab[0]);
    setTabs(newTabs);
    setActive(newTabs[0]);
  };

  const [hovering, setHovering] = useState(false);

  return (
    <>
      <div
        className={cn(
          "flex flex-row items-center mb-10 gap-4 justify-start [perspective:1000px] relative overflow-auto sm:overflow-visible no-visible-scrollbar max-w-full w-full h-auto",
          containerClassName
        )}
      >
        {propTabs.map((tab, idx) => (
          <button
            key={tab.title}
            onClick={() => {
              moveSelectedTabToTop(idx);
            }}
            disabled={!tab.content}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            className={cn("relative rounded-xl  px-10 py-3", tabClassName)}
            style={{
              transformStyle: "preserve-3d",
              display: window.innerWidth < 1200 && tab.notReady ? "none" : "unset"
            }}
          >
            {active.value === tab.value && (
              <motion.div
                layoutId="clickedbutton"
                transition={{ type: "spring", bounce: 0.3, duration: 0.6 }}
                className={cn(
                  "absolute inset-0  rounded-xl border-2 border-rootkey-light_blue ",
                  activeTabClassName
                )}
              />
            )}

            <span className="relative text-white w-fit flex">
              {active.value === tab.value && typeof tab.icon === 'string' ? <img src={tab.icon} alt={tab.title} className="h-[20px] scale-150 mr-2" /> : null}
              {tab.title}
              {active.value !== tab.value && tab.notReady ? <div className={cn(`ml-4 text-xs text-white/80 h-full border px-2 py-1 rounded-full`,
                tab.notReady.color === "green" ? "border-rootkey-green " : tab.notReady.color === "red" ? "border-rootkey-red " : "border-rootkey-pink"
              )}>{tab.notReady.title}</div> : null}
            </span>
          </button>
        ))}
      </div>
      <FadeInDiv
        tabs={tabs}
        active={active}
        key={active.value}
        hovering={hovering}
        className={cn("flex items-start justify-start", contentClassName)}
      />
    </>
  );
};

export const FadeInDiv: React.FC<{
  className?: string;
  key?: string;
  tabs: Tab[];
  active: Tab;
  hovering?: boolean;
}> = ({
  className,
  tabs,
  hovering,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerHeight, setContainerHeight] = useState('100vh');

    useEffect(() => {
      if (containerRef.current) {
        const tabDivs = containerRef.current.querySelectorAll('.tab-div');
        let maxHeight = 0;
        tabDivs.forEach((div) => {
          maxHeight = Math.max(maxHeight, div.clientHeight);
        });
        setContainerHeight(`${maxHeight + 20}px`);
      }
    }, [tabs, hovering]);

    const isActive = (tab: Tab): boolean => {
      return tab.value === tabs[0].value;
    };

    return (
      <div ref={containerRef} className="relative w-screen" style={{ height: containerHeight }}>
        {tabs.map((tab, idx) => (
          <motion.div
            key={tab.value}
            layoutId={tab.value}
            style={{
              scale: 1 - idx * 0.1,
              top: hovering ? idx * - 40 : 0,
              zIndex: -idx,
              opacity: idx < 4 ? 1 - idx * 1 : 0,
              height: isActive(tab) ? "" : "500px"
            }}
            animate={{
              y: isActive(tab) ? [0, 40, 0] : 0,
            }}
            className={cn("w-screen h-fit absolute left-0 tab-div", className)}        >
            {tab.content}
          </motion.div>
        ))}
      </div>
    );
  };