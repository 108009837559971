const aspect = 1.2;
const cameraZ = 300;

//const container = () => document.getElementById("scene-container");

const canvasWidth = () => window.innerWidth * 1.1;

const canvasHeight = () => canvasWidth() / aspect;

export { aspect, cameraZ, canvasWidth, canvasHeight };
