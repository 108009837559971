import React from 'react';
import { Helmet } from 'react-helmet';

interface HelmetProps {
  title: string,
  description: string,
  keywords: string,
  ogTitle: string,
  ogDescription: string,
  ogImage?: string,
  canonical: string
}

export default function CustomMetaTags(props: HelmetProps) {
  return (
    <Helmet>
      <title>{props.title}</title>
      {/*<!-- Facebook Meta Tags -->*/}
      <meta property="og:url" content="https://rootkey.ai" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={props.ogTitle} />
      <meta property="og:description" content={props.ogDescription} />
      <meta property="og:image" content={props.ogImage} />
      {/*<!-- Twitter Meta Tags -->*/}
      <meta property="twitter:domain" content="rootkey.ai" />
      <meta property="twitter:url" content="https://rootkey.ai" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={props.ogTitle} />
      <meta name="twitter:description" content={props.ogDescription} />
      <meta name="twitter:image" content={props.ogImage || "https://media.notcybersec.com/metatags/metaBanner.png"} />
      {/*<!-- Links Tags -->*/}
      <meta name="description" content={props.description} />
      <meta name="keywords" content={props.keywords} />
      <meta property="og:title" content={props.ogTitle} />
      <meta property="og:description" content={props.ogDescription} />
      <meta property="og:image" content={props.ogImage} />
      {props.canonical && <link rel="canonical" href={props.canonical} />}
    </Helmet>
  );
}
