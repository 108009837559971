import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { AOSInterface } from './HeroBtn';

interface HeroTextProps extends AOSInterface {
    text:string
}
export default function HeroText(props: HeroTextProps) {

	const { t } = useTranslation();
	useEffect(() => {
		AOS.init({duration:1000});
	}, [])
	

	return (<p data-aos={props.fade || "fade-up"}>{t(props.text)}</p>)
} 
