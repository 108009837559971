import { opportunities } from 'data/jobs';
import { createContext, useState, ReactNode } from 'react';

export interface Job {
    department: string[];
    title: string;
    type: "On-site" | "Remote" | "Hybrid";
    time: "Full-Time" | "Intern" | "Part-Time";
    location: string
    responsibilities: string[]
    qualifications: string[]
    additionalInformation?: string[]
}

interface JobContextData {
    position: Job
    cleanFields: () => void
    changePosition: (newPosition: Job) => void; 
}

interface JobProviderProps {
    children: ReactNode;
}

const initialPosition:Job = {
    title: opportunities[0].title,
    department: opportunities[0].department,
    type: opportunities[0].type,
    time: opportunities[0].time,
    location: opportunities[0].location,
    qualifications: opportunities[0].qualifications,
    additionalInformation: opportunities[0].additionalInformation,
    responsibilities: opportunities[0].responsibilities
}

export const JobContext = createContext({} as JobContextData)

export function JobProvider({ children }: JobProviderProps) {
    const [position, setPosition] = useState<Job>(initialPosition);
    function changePosition(newPosition: Job) {
        setPosition(newPosition);
      }
    function cleanFields() {
        setPosition(initialPosition);
    }

    return (
        <JobContext.Provider value={{
            position,
            cleanFields,
            changePosition
        }}>
            {children}
        </JobContext.Provider>
    )
}
