import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './styles.css';


interface PopUpProps {
    error?:boolean
    title?:string
    message?:string
    timer:number
    closeAnimation: () => void;
    show:boolean
}
export default function PopUp(props : PopUpProps) {
    const [show, setShow] = useState(false);
	const { t } = useTranslation();
    
    setTimeout(function () {
        if (!show && props.show) { 
            setShow(true); 
        }
    }, 1000);

    setTimeout(function () {
        if (show && props.show) { 
            setShow(false); 
            props.closeAnimation()
        }
    }, props.timer);

    function getColor():string {

        if (props.error) {
            return "rgba(255, 0, 0, 0.6)"
        }

        return "var(--primary-color-trans-1)"
    }
	return (
		<div id='PopUp'>
            <div className={show ? 'container active' : 'container'} 
            style={{background: `${getColor()}`, border: props.error ? '2px solid red' : '2px solid var(--primary-color)'}}
            >
                {props.title ? <h3>{t(`${props.title}`)} </h3> : ""}
                {props.message ? <h4>{t(`${props.message}`)}</h4> : ""}
            </div>
        </div>
    )
}