import { ContactContext } from "context/contactContext";
import { useContext } from "react";

export function useContact() {
    const context = useContext(ContactContext);
  
    if (!context) {
      throw new Error("useContact must be used within a JobProvider");
    }
  
    return context;
  }