import { GiWindTurbine } from "react-icons/gi";
import { SiFigshare } from "react-icons/si";
import { contacts } from "./consts";
import { SlLayers } from "react-icons/sl";
import { RiSecurePaymentFill } from "react-icons/ri";

export const abasInfo = [
    {
        aba: 0,
        urlMain: "/platform",
        title: 'NavBar.platform',
        links: [
            {
                title: 'NavBar.Platform-links.0.title',
                description: 'NavBar.Platform-links.0.description',
                linkTo: '/platform',
                icon: SlLayers
            },
            {
                title: 'NavBar.Platform-links.3.title',
                description: 'NavBar.Platform-links.3.description',
                linkTo: '/product/damc',
                icon: GiWindTurbine
            },
        ]
    },
    {
        aba: 1,
        urlMain: "/resources",
        title: 'NavBar.resorces',
        links: [
            {
                title: 'NavBar.resorces-links.2.title',
                description: "NavBar.resorces-links.2.description",
                linkTo: contacts.APIdocs,
            },
            {
                title: 'NavBar.resorces-links.1.title',
                description: "NavBar.resorces-links.1.description",
                linkTo: contacts.blog
            },
            {
                title: 'NavBar.resorces-links.3.title',
                description: "NavBar.resorces-links.3.description",
                linkTo: '/mediakit',
            },
            {
                title: 'NavBar.resorces-links.4.title',
                description: "NavBar.resorces-links.4.description",
                linkTo: '/careers',
            },
            {
                title: 'NavBar.resorces-links.0.title',
                description: "NavBar.resorces-links.0.description",
                linkTo: '/legal'
            }
        ]
    },
    {
        aba: 2,
        urlMain: "/company",
        title: 'NavBar.company',
        links: [
            {
                title: 'NavBar.Company-links.0.title',
                description: "NavBar.Platform-links.0.description",
                linkTo: '/company'
            },
            {
                title: 'NavBar.Company-links.2.title',
                description: "NavBar.Company-links.2.description",
                linkTo: '/contact'
            },
        ]
    }
]
