import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDocTitle } from '../../hooks/useDocumentVar';
import 'aos/dist/aos.css';
import PT from '../../i18n/pt/cookiesPolicy.pt.json'
import GB from '../../i18n/en/cookiesPolicy.en.json'
import { Hero } from 'components/Hero';
import LegalComponent from 'components/LegalComponent/legalComponent';

export default function CookiesPolicy() {
    const { t, i18n } = useTranslation();
    useDocTitle(t('CookiesPolicy.title') + " - ROOTKey")
    var CookiesPolicyArray

    if (i18n.language === "en") {
        CookiesPolicyArray = GB
    } else {
        CookiesPolicyArray = PT
    }

    return (
        <main id='PrivacyTerms'>
            <Hero.Root type='simple'>
                <Hero.Subtitle fade={'fade-right'} text='CookiesPolicy.title' />
                <Hero.Title fade={'fade-right'}text='Legal.Cookies.description' />
                <Hero.Img fade={null} imgUrl='https://media.notcybersec.com/website/Legal/cookies.jpg' />
            </Hero.Root>
            <LegalComponent legalPar={CookiesPolicyArray.CookiesPolicy.array} />

        </main>
    )

}