import React from 'react';
import './styles.css';
import { FaArrowDown } from 'react-icons/fa';

export default function Scrool() {

    const handleClick = () => {
        window.scrollBy({
            top: window.innerHeight,
            behavior: "smooth"
        });
    };

    return (

        <div id='scrool' onClick={handleClick}>
            <span className="circle">
                <FaArrowDown className="arrow" />
            </span>
        </div>

    )
} 
