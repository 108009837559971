import { MdWhatsapp } from "react-icons/md"; 
import React from 'react';
import { BiBookReader } from "react-icons/bi";
import { AiFillApi } from "react-icons/ai";
import { BiCookie } from "react-icons/bi";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdArrowOutward } from "react-icons/md";
import { CgIfDesign } from "react-icons/cg";
import './styles.css';
import { useTranslation } from 'react-i18next';
import { isURL, useDocTitle } from '../../hooks/useDocumentVar';
import { Hero } from 'components/Hero';
import { Link } from "react-router-dom";
import { contacts, social } from "data/consts";
import BasicBtn from "components/BasicBtn";
import { AiFillGithub, AiFillLinkedin, AiFillTwitterCircle, AiOutlineInstagram } from "react-icons/ai";
import { SlArrowDown } from "react-icons/sl";

export default function Resources() {

    const { t } = useTranslation();
    useDocTitle(`${t('NavBar.resorces')} - ROOTKey`)

    const boxes = [
        {
            subject: "resources.boxes.dev.subject",
            title: "resources.boxes.dev.title",
            description: "resources.boxes.dev.description",
            icon: AiFillApi,
            link: contacts.APIdocs,
        },
        {
            subject: "resources.boxes.media.subject",
            title: "resources.boxes.media.title",
            description: "resources.boxes.media.description",
            icon: CgIfDesign,
            link: '/mediakit',
        },
        {
            subject: "resources.boxes.news.subject",
            title: "resources.boxes.news.title",
            description: "resources.boxes.news.description",
            icon: BiBookReader,
            link: contacts.blog,
        }
    ]
    const socialMedias = [
        {
            name: "X",
            description: "resources.social.socialMedias.Twitter",
            logo: AiFillTwitterCircle,
            link: social.twitter
        },
        {
            name: "WhatsApp",
            description: "resources.social.socialMedias.github",
            logo: MdWhatsapp,
            link: social.whatsapp
        },
        {
            name: "Linkedin",
            description: "resources.social.socialMedias.linkedin",
            logo: AiFillLinkedin,
            link: social.linkedin
        },
        {
            name: "Instagram",
            description: "resources.social.socialMedias.instagram",
            logo: AiOutlineInstagram,
            link: social.instagram
        },
        {
            name: "Github",
            description: "resources.social.socialMedias.github",
            logo: AiFillGithub,
            link: social.github
        },
    ]
    return (
        <main id='resources'>

            <Hero.Root type='simple'>
                <Hero.Subtitle fade={'fade-right'} text='Explore Our Resources' />
                <Hero.Title fade={'fade-right'} text='Embark on a Journey of Discovery' />
                <Hero.Img fade={null} imgUrl='https://media.notcybersec.com/website/Home/homeSection1.png' />
            </Hero.Root>

            <div className='resume'>
                {boxes.map((data, index) => (
                    <div className="wapper">
                        <Link className='_box' to={data.link} target={isURL(data.link) ? "_blank" : ''} rel={isURL(data.link) ? "noreferrer" : ''} key={index}>
                            <p className="subject">{t(data.subject)}</p>
                            <div>
                                <h3>{t(data.title)}</h3>
                                <p>{t(data.description)}</p>
                            </div>
                            <data.icon />
                        </Link>
                        <div className="effect _2" />
                        <div className="effect" />
                    </div>
                ))}

            </div>

            <div className='social'>
                <div className="text">
                    <h1>{t('resources.social.title')}</h1>
                    <p>{t('resources.social.description')}</p>
                    <BasicBtn href={social.linktr} fade={null} text={t('resources.social.btn')} style={{ border: "none", color: "var(--rootkey-green)", padding: "0" }} />
                </div>
                <div className="socialMedia">
                    {socialMedias.map((data, index) => (
                        <div key={index} className="platform">
                            <div>
                                <data.logo className="logo" />
                                <a href={data.link} target="_blank"  rel="noreferrer" >{data.name} <MdArrowOutward size={15} /></a>
                            </div>
                            <span>{t(data.description)}</span>
                        </div>
                    ))}
                </div>
            </div>

            <div className="hire">
                <div className="_1">
                    <img key={1} src='https://media.notcybersec.com/website/Company/team/png/img_2.jpeg' alt={"team"} />
                    <img key={2} src='https://media.notcybersec.com/website/Company/team/png/img_1.png' alt={"team"} />
                    <img key={3} src='https://media.notcybersec.com/website/Company/team/png/carlota.jpg' alt={"team"} />
                    <img key={4} src='https://media.notcybersec.com/website/Company/team/png/img_3.jpeg' alt={"team"} />
                    <img key={5} src='https://media.notcybersec.com/website/Company/team/png/img_5.jpg' alt={"team"} />
                    <img key={6} src='https://media.notcybersec.com/website/Company/team/png/img_4.jpg' alt={"team"} />
                    <img key={7} src='https://media.notcybersec.com/website/Company/team/png/img_1.jpg' alt={"team"} />
                    <img key={8} src='https://media.notcybersec.com/website/Company/team/png/img_3.jpg' alt={"team"} />
                    <img key={9} src='https://media.notcybersec.com/website/Company/team/png/img_1.jpeg' alt={"team"} />
                    <img key={10} src='https://media.notcybersec.com/website/Company/team/png/img_2.jpg' alt={"team"} />
                </div>
                <div className="_2">
                    <h2>{t('resources.hire.title')}</h2>
                    <span>{t('resources.hire.description')}</span>
                    <BasicBtn href={'/careers'} text={t('resources.hire.btn')} fade={null} arrow style={{ border: "none", padding: "0", marginTop: "20px", width: "fit-content" }} />
                </div>
                <SlArrowDown className="arrow1" />
                <SlArrowDown className="arrow2" />
            </div>

            <Link to="/legal" className="legal">
                <div className="_3">
                    <h3><BiCookie />{t('resources.legal.title')}</h3>
                    <span>{t('resources.legal.description')}</span>
                </div>
                <div className="arrows">
                    <MdKeyboardArrowRight />
                    <MdKeyboardArrowRight />
                    <MdKeyboardArrowRight />
                </div>
            </Link>

        </main>
    )
} 
