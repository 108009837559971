import { BsGlobeAmericas } from "react-icons/bs"; 
import React, { useEffect, useState } from 'react';

import './styles/navBarDesktop.css';
import { useTranslation } from 'react-i18next';
import LogoROOTKey from 'components/LogoROOTKey';
import { contacts } from 'data/consts';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { getToTop } from 'hooks/useDocumentVar';
import NavBarBtn from './navBarBtn';
import NavBarBox from './navBarBox';
import { abasInfo } from '../../data/navBar';
import BasicBtn from 'components/BasicBtn';

import pt from '../../assets/pt.svg'
import us from '../../assets/us.svg'

export default function NavBarDesktop() {
	const { t, i18n } = useTranslation();

	const [navBar, setNavBar] = useState<boolean>(false);
	const [drop, setDrop] = useState({
		state: false,
		aba: 0
	});

	function changeBackground(): void {
		if (window.scrollY >= 70) {
			setNavBar(true)
		} else {
			setNavBar(false)
		}
	}

	function languageHandler() {

		localStorage.setItem('language', i18n.language === 'pt' ? 'en' : "pt");
		i18n.changeLanguage( i18n.language === 'pt' ? 'en' : "pt")
	}

	useEffect(() => {
		if (localStorage.getItem('language') != null) {
			i18n.changeLanguage(localStorage.getItem('language')?.toString());
		}
	}, [i18n])


	useEffect(() => {
		AOS.init({ duration: 3000 });
	}, [])

	window.addEventListener("scroll", changeBackground);

	return (
		<nav className={navBar ? 'NavBar active' : 'NavBar'}>
			<div className="nav-main">
				<LogoROOTKey href='/' />
				<div className="links">
					<Link
						onMouseOut={() => { setDrop(x => ({ ...x, state: false })) }}
						onMouseOver={() => { setDrop({ state: true, aba: 0 }) }}
						onClick={() => { setDrop({ state: !drop.state, aba: 0 }) }}
						to={"/platform"}
					>{t('NavBar.platform')}
					</Link>

					<Link
						onMouseOut={() => { setDrop(x => ({ ...x, state: false })) }}
						onMouseOver={() => { setDrop({ state: true, aba: 2 }) }}
						onClick={() => { setDrop({ state: !drop.state, aba: 2 }) }}
						to={"/company"}
					>{t('NavBar.company')}
					</Link>

					<Link
						onMouseOut={() => { setDrop(x => ({ ...x, state: false })) }}
						onMouseOver={() => { setDrop({ state: true, aba: 1 }) }}
						onClick={() => { setDrop({ state: !drop.state, aba: 1 }) }}
						to={"/resources"}
					>{t('NavBar.resorces')}
					</Link>

					<Link
						onClick={() => { setDrop({ state: !drop.state, aba: 3 }) }}
						to={"/contact"}
					>{t('NavBar.Company-links.2.title')}
					</Link>


				</div>
				<div className='flex gap-2'>
					<BasicBtn href={contacts.platform} text={t('NavBar.Login')} fade={null} />
					<button
						type='button'
						onClick={languageHandler}
						className='h-[38px] transition-all duration-500 group aspect-square rounded-lg w-auto bg-background border border-rootkey-light_blue overflow-hidden p-[7px] hover:bg-white/10'
					>
						<BsGlobeAmericas className="w-full h-auto opacity-70 group-hover:hidden" />
						<img
							className='opacity-75 rounded-full group-hover:block hidden'
							src={i18n.language === 'pt' ? pt : us}
							alt={i18n.language === 'pt' ? "Português" : "Inglês"}
						/>
					</button>
				</div>
			</div>

			<div className={drop.state ? 'drop' : 'drop inactive'}
				onMouseOut={() => { setDrop(x => ({ ...x, state: false })) }}
				onMouseOver={() => { setDrop(x => ({ ...x, state: true })) }}
			>
				{abasInfo[drop.aba] && drop.state && abasInfo[drop.aba].links.length !== 0 ?
					<NavBarBox title={t(abasInfo[drop.aba].title)}>
						{abasInfo[drop.aba].links.map((data: any, index: number) => (
							<NavBarBtn icon={data.icon} description={data.description} href={data.linkTo} title={data.title} index={index} onClick={getToTop} key={index} />
						))}
					</NavBarBox> : null
				}
			</div>
		</nav>
	)
}