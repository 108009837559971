import { FiCopy } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import React from 'react';

import './styles.css'
import { useTranslation } from 'react-i18next';
import { useDocTitle } from '../../hooks/useDocumentVar';
import { Hero } from 'components/Hero';
import mediaPT from '../../i18n/pt/mediakit.pt.json'
import mediaGB from '../../i18n/en/mediakit.en.json'

export default function Mediakit() {
    const { t, i18n } = useTranslation();
    var arrayHero;
    useDocTitle("Media Kit - ROOTKey")

    const handleDownloadClick = (ref: string, fileName: string) => {
        const link = document.createElement('a');
        link.href = ref;
        link.download = fileName;
        link.click();
    };

    if (i18n.language === "en") {
        arrayHero = mediaGB.mediakit.hero.arrayHero
    } else {
        arrayHero = mediaPT.mediakit.hero.arrayHero
    }
    const copyCode = (code: string) => {
        const tempTextarea = document.createElement('textarea');
        tempTextarea.value = code;
        document.body.appendChild(tempTextarea);
        tempTextarea.select();
        document.execCommand('copy');
        document.body.removeChild(tempTextarea);
    };


    return (
        <main id='mediakit'>
            <Hero.Root type='simple'>
                <Hero.Tags tags={arrayHero} />
                <Hero.Title fade={'fade-right'} text='mediakit.hero.title' />
                <Hero.Subtitle fade={'fade-right'} text='mediakit.hero.subtitle' />
                <Hero.Img className="costumImg" fade={null} imgUrl="https://media.notcybersec.com/website/mediakit/assetMediaKit.png" />
            </Hero.Root>
            <div className='guide'>

                <div className='logos'>
                    <div>
                        <h2>{t('mediakit.logo.title')}</h2>
                        <p>{t('mediakit.logo.text1')}<br /><br />
                            {t('mediakit.logo.text2')}<br /><br />
                            {t('mediakit.logo.text3')}
                        </p>
                        <button type="button" onClick={() => handleDownloadClick('https://media.notcybersec.com/website/mediakit/files/mediaKit.rar', "MediaKit - ROOTKey")}>{t('mediakit.logo.btn')}<IoIosArrowForward /></button>
                    </div>
                    <div>
                        <h4>{t('mediakit.logo.title2')}</h4>
                        <img src="https://media.notcybersec.com/website/mediakit/icon.png" alt="icon" />
                        <img src="https://media.notcybersec.com/website/mediakit/iconRules.png" alt="iconRules" />
                    </div>
                    <div>
                        <h4>{t('mediakit.logo.title3')}</h4>
                        <img src="https://media.notcybersec.com/website/mediakit/vertical.png" alt="vertical" />
                        <img src="https://media.notcybersec.com/website/mediakit/verticalRules.png" alt="verticalRules" />
                        <h4>{t('mediakit.logo.title4')}</h4>
                        <img src="https://media.notcybersec.com/website/mediakit/horizontal.png" alt="horizontal" />
                        <img src="https://media.notcybersec.com/website/mediakit/hrorizontalRules.png" alt="hrorizontalRules" />
                    </div>
                </div>
                <div className='logos _2'>
                    <h2>{t('mediakit.logoPB.title')}</h2>
                    <div className="line">
                        <div>
                            <h4>{t('mediakit.logo.title4')}</h4>
                            <img src="https://media.notcybersec.com/website/mediakit/pb_horizontal_black.png" alt="icon" />
                            <div className="code-container">
                                <pre id="codeBlock" className="codeBlock">
                                    {'<a href="https://rootkey.ai"><img src="https://media.notcybersec.com/website/mediakit/pb_horizontal_black.png" alt="Powered by ROOTKey"/></a>'}
                                </pre>
                                <button id="copyBtn" className="btnCode" onClick={() => { copyCode('<a href="https://rootkey.ai"><img src="https://media.notcybersec.com/website/mediakit/pb_horizontal_black.png" alt="Powered by ROOTKey"/></a>') }}><FiCopy />{t('mediakit.logoPB.btn')}</button>
                            </div>
                            <img style={{ background: "#ccc2" }} src="https://media.notcybersec.com/website/mediakit/pb_horizontal_white.png" alt="iconRules" />
                            <div className="code-container">
                                <pre id="codeBlock" className="codeBlock">
                                    {'<a href="https://rootkey.ai"><img src="https://media.notcybersec.com/website/mediakit/pb_horizontal_white.png" alt="Powered by ROOTKey"/></a>'}
                                </pre>
                                <button id="copyBtn" className="btnCode" onClick={() => { copyCode('<a href="https://rootkey.ai"><img src="https://media.notcybersec.com/website/mediakit/pb_horizontal_white.png" alt="Powered by ROOTKey"/></a>') }}><FiCopy />{t('mediakit.logoPB.btn')}</button>
                            </div>

                        </div>
                        <div>
                            <h4>{t('mediakit.logo.title3')}</h4>

                            <img src="https://media.notcybersec.com/website/mediakit/pb_vertical_black.png" alt="vertical" />
                            <div className="code-container">
                                <pre id="codeBlock_2" className="codeBlock">
                                    {`<a href="https://rootkey.ai"><img src="https://media.notcybersec.com/website/mediakit/pb_vertical_black.png" alt="Powered by ROOTKey"/></a>`}
                                </pre>
                                <button id="copyBtn" className="btnCode" onClick={() => { copyCode('<a href="https://rootkey.ai"><img src="https://media.notcybersec.com/website/mediakit/pb_vertical_black.png" alt="Powered by ROOTKey"/></a>') }}><FiCopy />{t('mediakit.logoPB.btn')}</button>
                            </div>

                            <img style={{ background: "#ccc2" }} src="https://media.notcybersec.com/website/mediakit/pb_vertical_white.png" alt="verticalRules" />
                            <div className="code-container">
                                <pre id="codeBlock_2" className="codeBlock">
                                    {`<a href="https://rootkey.ai"><img src="https://media.notcybersec.com/website/mediakit/pb_vertical_white.png" alt="Powered by ROOTKey"/></a>`}
                                </pre>
                                <button id="copyBtn" className="btnCode" onClick={() => { copyCode('<a href="https://rootkey.ai"><img src="https://media.notcybersec.com/website/mediakit/pb_vertical_white.png" alt="Powered by ROOTKey"/></a>') }}><FiCopy />{t('mediakit.logoPB.btn')}</button>
                            </div>
                        </div>
                        <div>
                            <p>{t('mediakit.logoPB.text1')}<br /><br />
                                {t('mediakit.logoPB.text2')}<br /><br />
                                {t('mediakit.logoPB.text3')}
                            </p>
                            <button type="button" onClick={() => handleDownloadClick('https://media.notcybersec.com/website/mediakit/files/powered_by.rar', "Powered By - ROOTKey")}>{t('mediakit.logo.btn')}<IoIosArrowForward /></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='div_type'>
                <h2 className='typeTytle'>{t('mediakit.typo.title')}</h2>
                <div className='type'>
                    <div>
                        <img src="https://media.notcybersec.com/website/mediakit/kilby.png" alt="type1" />
                        <article>
                            <p>{t('mediakit.typo._1.text1')}</p>
                            <p>{t('mediakit.typo._1.text2')}</p>
                            <button type="button" onClick={() => handleDownloadClick('https://media.notcybersec.com/website/mediakit/files/Kilby.rar', "Type Kilby - ROOTKey")} >{t('mediakit.typo._1.btn')}<IoIosArrowForward /></button>
                        </article>
                    </div>
                    <div>
                        <img src="https://media.notcybersec.com/website/mediakit/raleway.png" alt="type1" />
                        <article>
                            <p>{t('mediakit.typo._2.text1')}</p>
                            <p>{t('mediakit.typo._2.text2')}</p>
                            <button type="button" onClick={() => handleDownloadClick('https://media.notcybersec.com/website/mediakit/files/Raleway.zip', "Type Raleway - ROOTKey")}>{t('mediakit.typo._2.btn')}<IoIosArrowForward /></button>

                        </article>
                    </div>
                    <div>
                        <img src="https://media.notcybersec.com/website/mediakit/fira_code.png" alt="type1" />
                        <article>
                            <p>{t('mediakit.typo._3.text1')}</p>
                            <p>{t('mediakit.typo._3.text2')}</p>
                            <p>{t('mediakit.typo._3.text3')}</p>
                            <button type="button" onClick={() => handleDownloadClick('https://media.notcybersec.com/website/mediakit/files/Fira_Code.zip', "Type Fira_Code - ROOTKey")}>{t('mediakit.typo._3.btn')}<IoIosArrowForward /></button>

                        </article>
                    </div>
                </div>
            </div>
            <div className='guide'>
                <h2>{t('mediakit.colors.title')}</h2>
                <div className="colors">
                    <div className="pantone" style={{ backgroundColor: "#0000FF" }}>
                        <div className="frame">
                            <div>
                                <span>Hex: 0000FF</span>
                                <span>R:0 G:0 B:255</span>
                            </div>
                        </div>
                    </div>
                    <div className="pantone" style={{ backgroundColor: "#6100FF" }}>
                        <div className="frame">
                            <div>
                                <span>Hex: 6100FF</span>
                                <span>R:97 G:0 B:255</span>
                            </div>
                        </div>
                    </div>
                    <div className="pantone" style={{ backgroundColor: "#B800E6" }}>
                        <div className="frame">
                            <div>
                                <span>Hex: B800E6</span>
                                <span>R:184 G:0 B:230</span>
                            </div>
                        </div>
                    </div>
                    <div className="pantone" style={{ backgroundColor: "#FF0054" }}>
                        <div className="frame">
                            <div>
                                <span>Hex: FF0054</span>
                                <span>R:255 G:0 B:84</span>
                            </div>
                        </div>
                    </div>
                    <div className="pantone" style={{ backgroundColor: "#00FF85" }}>
                        <div className="frame">
                            <div>
                                <span>Hex: 00FF85</span>
                                <span>R:0 G:255 B:133</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pdf" onClick={() => handleDownloadClick('https://media.notcybersec.com/website/mediakit/files/BrandingGuidelines_RK.rar', "Branding Guidelines - ROOTKey")}>

                <div className="cube" />
                <div className="cube" />
                <div>
                    <h2>{t('mediakit.pdf.title')}</h2>
                    <p>{t('mediakit.pdf.text')}</p>
                </div>
                <div className="arrows">
                    <IoIosArrowDown />
                    <IoIosArrowDown />
                    <IoIosArrowDown />
                    <IoIosArrowDown />
                    <IoIosArrowDown />
                    <IoIosArrowDown />
                    <IoIosArrowDown />
                    <IoIosArrowDown />
                    <IoIosArrowDown />
                    <span>{t('mediakit.pdf.btn')}</span>
                </div>
            </div>
        </main>
    )

}