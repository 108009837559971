import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDocTitle } from '../../hooks/useDocumentVar';
import AOS from 'aos';
import 'aos/dist/aos.css';
import PT from '../../i18n/pt/terms.pt.json'
import GB from '../../i18n/en/terms.en.json'
import {Hero} from 'components/Hero';
import LegalComponent from 'components/LegalComponent/legalComponent';

export default function TermsAndConditions() {
    const { t, i18n } = useTranslation();
    useDocTitle(t('Terms.title') + " - ROOTKey")
    var Terms

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, [])

    if (i18n.language === "en") {
        Terms = GB
    } else {
        Terms = PT
    }


    return (
        <main id='PrivacyTerms'>

            <Hero.Root type='simple'>
                <Hero.Subtitle fade={'fade-right'} text='Terms.title' />
                <Hero.Title fade={'fade-right'}text='Legal.Terms.description' />
                <Hero.Img fade={null} imgUrl='https://media.notcybersec.com/website/Legal/terms.jpg' />
            </Hero.Root>
            
            <LegalComponent legalPar={Terms.Terms.array} />

        </main>
    )

}