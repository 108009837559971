import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { AOSInterface } from './HeroBtn';

interface HeroTitleProps extends AOSInterface {
    text:string
}
export default function HeroTitle(props: HeroTitleProps) {

	const { t } = useTranslation();
	useEffect(() => {
		AOS.init({duration:1000});
	}, [])
	

	return (<h1 data-aos={props.fade || "fade-up"}>{t(props.text)}</h1>)
} 
