import React, { HTMLAttributes, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { AOSInterface } from './HeroBtn';

interface SectionImgProps extends AOSInterface, HTMLAttributes<HTMLImageElement> {
    imgUrl:string
    alt?: string
}
export default function HeroImg(props: SectionImgProps) {

	useEffect(() => {
		AOS.init({duration:1000});
	}, [])
	

	return (<img data-aos={props.fade || props.fade === null ? '' : "fade-up"} src={props.imgUrl} alt={props.alt || ''} {...props} />)
} 
