import { TracingBeam } from 'components/ui/tracing-beam'
import './styles.css'

interface TermsProps {
    legalPar: {
        title: string
        description: string
    }[],
}
export default function LegalComponent(props: TermsProps) {

    const all = props.legalPar.map((data, index) =>
        <article key={index} id={"policy" + index}>
            <h3>{data.title}</h3>
            <p>{data.description}</p>
        </article>
    )

    const titles = props.legalPar.map((data, index) =>
        <a href={"#policy" + index} key={index}>{data.title}</a>
    )

    return (
        <main id='LegalComponent'>
            <TracingBeam>
                <aside className="navbar">
                    {titles}
                </aside>
                <div className='terms'>
                    {all}
                </div>
            </TracingBeam>
        </main>
    )
}