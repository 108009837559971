import { useEffect, useState } from "react";

const useDocTitle = (title: string) => {
  const [document_title, setDoucmentTitle] = useState(title || "ROOTKey");
  
   useEffect(() => {
    document.title = document_title; 
  },[document_title]);

  return [document_title, setDoucmentTitle];
};

const isURL = (str: string | URL) => {
  try {
    new URL(str);
    return true;
  } catch (error) {
    return false;
  }
};

const getToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};
export {useDocTitle, isURL, getToTop};