import { HiOutlineExternalLink } from "react-icons/hi";
import React, { ElementType } from 'react';
import './styles/navBarBtn.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getToTop, isURL } from 'hooks/useDocumentVar';

interface navBarBtnProps {
    href: string,
    title: string,
    description: string
    index?: number
    icon?: ElementType
    onClick?: () => void
}

export default function NavBarBtn(props: navBarBtnProps) {
    const { t } = useTranslation();

    return (<Link className="btnNavBar"
        target={isURL(props.href) ? "_blank" : ''}
        rel="noreferrer"
        to={props.href}
        key={props.href}
        onClick={getToTop}
    >
        <div className="titleLink">
            {props.icon? <props.icon className="icon" /> : null}
            <h3>{t(props.title)}</h3>
            {isURL(props.href) ? <HiOutlineExternalLink /> : ''}
        </div>
        <p>{t(props.description)}</p>
    </Link>

    )
}