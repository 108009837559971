import React, { FormEvent, useState } from 'react';

import './styles.css'
import { useTranslation } from 'react-i18next';
import { useDocTitle } from '../../hooks/useDocumentVar';
import { Hero } from 'components/Hero';
import { useJob } from 'hooks/useJob';
import { useContact } from 'hooks/useContact';
import PopUp from 'components/PopUp';
import { HiOutlineExternalLink } from 'react-icons/hi';

export default function JobForm() {
    const { t } = useTranslation();
    const { position } = useJob()
    const { contact, sendEmail, newsletterSubscribe } = useContact()
    const [popUpAnimation, setPopUpAnimaton] = useState<boolean>(false)
    const [newsletter, setNewsletter] = useState<boolean>(false)
    const [popUpForm, setPopUpForm] = useState({
        error: false,
        timer: 6000,
        mensage: 'PopUpMessages.ContactUs.Sucess.message',
        title: 'PopUpMessages.ContactUs.Sucess.title',
    })

    const [formInfo, setFormInfo] = useState<{
        name: string,
        email: string,
        cv: string,
        infoMsg: string
    }>({
        name: contact.firstName,
        email: contact.email,
        cv: '',
        infoMsg: ''
    })

    useDocTitle(`${position.title} - ROOTKey`)

    function handleSubmit(event: FormEvent) {
        event.preventDefault();

        try {
            sendEmail({
                firstName: formInfo.name,
                lastName: '',
                email: formInfo.email,
                phoneNumber: '-',
                companyName: '-',
                subject: `Application for ${position.title}`,
                companySize: '-',
                message: `${formInfo.infoMsg} 
                <br/><br/>
                <span id="cv"> ${formInfo.cv} </span>
                `,
                category: 'job'
            })

            if (newsletter) {
                newsletterSubscribe(formInfo.email)
            }

            setPopUpForm({
                error: false,
                timer: 6000,
                mensage: 'PopUpMessages.ContactUs.Sucess.message',
                title: 'PopUpMessages.ContactUs.Sucess.title',
            })
            setPopUpAnimaton(true);

            setFormInfo({
                name: contact.firstName,
                email: contact.email,
                cv: '',
                infoMsg: ''
            })
            setNewsletter(false)

        } catch (error) {
            setPopUpForm({
                error: true,
                timer: 6000,
                mensage: 'PopUpMessages.ContactUs.Fail.message',
                title: 'PopUpMessages.ContactUs.Fail.title',
            })
            setPopUpAnimaton(true)
        }
    }
    return (
        <main id='JobForm'>
            <PopUp
                show={popUpAnimation}
                timer={popUpForm.timer}
                closeAnimation={() => { setPopUpAnimaton(!popUpAnimation) }}
                title={popUpForm.title}
                message={popUpForm.mensage}
                error={popUpForm.error}
            />
            <Hero.Root type='simple' style={{ maxHeight: "70vh" }}>
                <Hero.Subtitle fade={'fade-right'} text={position.type} />
                <Hero.Title fade={'fade-right'} text={position.title} />
                <div style={{ display: "flex", gap: "10px" }}>
                    <span className='tag _1'>{position.location}</span>
                    {position.department.map((data, index) => (<span className='tag' key={index}>{data}</span>))}
                </div>
                <Hero.Img imgUrl={'https://media.notcybersec.com/website/jobapply.jpg'} alt='img' fade={null} />
            </Hero.Root>
            <div className='texts'>
                <h3>Who is ROOTKey?</h3>
                <span>ROOTKey is a pioneering data security startup based in Portugal. We specialize in providing cutting-edge blockchain-based solutions to enhance data security and integrity in digital environments.</span>
                <span>Our product portfolio includes the SDS (Secure Data System), a state-of-the-art platform that leverages blockchain technology to create a secure and tamper-proof environment for data storage and sharing. In addition, we offer a range of solutions for Decentralized Digital Identity (DDI), enabling secure authentication and identity management online.</span>
                <span>With an unwavering commitment to cybersecurity, ROOTKey is on a mission to empower businesses, organizations, and individuals by ensuring the safety, authenticity, and validity of their data. We firmly believe that security is the cornerstone of a more trustworthy and efficient digital future.</span>

                <h3>Responsibilities:</h3>
                {position.responsibilities.map((data, index) => <span key={index} className='item'>{data}</span>)}
                <h3>Qualifications:</h3>
                {position.qualifications.map((data, index) => <span key={index} className='item'>{data}</span>)}

                {position.additionalInformation ?
                    <>
                        <h3>Additional Information:</h3>
                        {position.additionalInformation.map((data, index) => <span key={index} className='item'>{data}</span>)}

                    </> : null
                }
                <span className='note'>If you think you have what it takes, but don't necessarily meet every single point on the job description, please still get in touch. We'd love to have a chat and see if you could be a great fit.</span>
            </div>
            <form onSubmit={handleSubmit}>
                <h3>Submit your application</h3>
                <div className='flex'>
                    <div>
                        <label htmlFor="name">Full name *</label>
                        <input required type="text" name='name' onChange={(value) => { setFormInfo({ ...formInfo, name: value.target.value }) }} value={formInfo.name} />
                    </div>
                    <div>
                        <label htmlFor="email">Email *</label>
                        <input required type="mail" name='email' onChange={(value) => { setFormInfo({ ...formInfo, email: value.target.value }) }} value={formInfo.email} />
                    </div>
                </div>
                <label htmlFor="cv">Resume/CV Url *</label>
                <input required type="url" name='cv' onChange={(value) => { setFormInfo({ ...formInfo, cv: value.target.value }) }} value={formInfo.cv} />
                <label htmlFor="info">Additional information</label>
                <textarea rows={15} name='info' value={formInfo.infoMsg} onChange={(value) => { setFormInfo({ ...formInfo, infoMsg: value.target.value }) }} />
                <div className='flex switch'>
                    <label className="switch">
                        <input type="checkbox" required />
                        <span className="sliderComp"></span>
                    </label>
                    <span>{t('Contact.data')}<a href="/legal">{t('cookie.legal')}<HiOutlineExternalLink size={15} /></a></span>
                </div>
                <div className='flex switch'>
                    <label className="switch">
                        <input type="checkbox" onChange={(event) => { setNewsletter(event.target.checked) }} checked={newsletter} />
                        <span className="sliderComp"></span>
                    </label>
                    <span>{t('Contact.newsletter')}</span>
                </div>
                <button type='submit'>
                    <label>Submit application</label>
                </button>
            </form>
        </main >
    )

}