import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDocTitle } from '../../hooks/useDocumentVar';
import PT from '../../i18n/pt/disclamer.pt.json'
import GB from '../../i18n/en/disclamer.en.json'
import {Hero} from 'components/Hero';
import LegalComponent from 'components/LegalComponent/legalComponent';

export default function Disclamer() {
    const { t, i18n } = useTranslation();
    useDocTitle(t('Disclamer.title') + " - ROOTKey")
    var DisclamerArray


    if (i18n.language === "en") {
        DisclamerArray = GB
    } else {
        DisclamerArray = PT
    }

    return (
        <main id='PrivacyTerms'>

            <Hero.Root type='simple'>
                <Hero.Subtitle fade={'fade-right'} text='Disclamer.title' />
                <Hero.Title fade={'fade-right'}text='Legal.Disclamer.description' />
                <Hero.Img fade={null} imgUrl='https://media.notcybersec.com/website/Legal/disclamer.jpg' />
            </Hero.Root>
            <LegalComponent legalPar={DisclamerArray.Disclamer.array} />

        </main>
    )

}