import { JobContext } from "context/jobContext";
import { useContext } from "react";

export function useJob() {
    const context = useContext(JobContext);
  
    if (!context) {
      throw new Error("useJob must be used within a JobProvider");
    }
  
    return context;
  }