import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import BasicBtn, { BasicBtnProps } from 'components/BasicBtn';

export interface AOSInterface {
    fade?: "fade-up" | "fade-left" | "fade-right" | null
}

export default function HeroBtn(props: BasicBtnProps) {
	const { t } = useTranslation();

	useEffect(() => {
		AOS.init({ duration: 1000 });
	}, [])


	return (
		<BasicBtn {...props}>
			{t(props.text)}
		</BasicBtn>)
} 
