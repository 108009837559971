import React, { useEffect } from 'react';

import './styles.css'
import { useTranslation } from 'react-i18next';
import { useDocTitle } from '../../../hooks/useDocumentVar';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Hero } from 'components/Hero';


export default function Legal() {
    const { t } = useTranslation();
    useDocTitle(t('Legal.Hero.title') + " - ROOTKey")

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, [])


    return (
        <main id='legal'>
            
            <Hero.Root type='simple'>
                <Hero.Subtitle fade={'fade-right'} text='Legal.Hero.title' />
                <Hero.Title fade={'fade-right'}text='Legal.Hero.description' />
                <Hero.Img fade={null} imgUrl='https://media.notcybersec.com/website/Legal/legal.jpg' />
            </Hero.Root>

            <div className='gride'>
                <a href='/legal/privacy-policy' className='pri'>
                    <h2>{t('Legal.Privacy.title')}</h2>
                    <p>{t('Legal.Privacy.description')}</p>
                </a>
                <a href='/legal/terms-conditions' className='term'>
                    <h2>{t('Legal.Terms.title')}</h2>
                    <p>{t('Legal.Terms.description')}</p>
                </a>
                <a href='/legal/cookies-policy' className='cookie'>
                    <h2>{t('Legal.Cookies.title')}</h2>
                    <p>{t('Legal.Cookies.description')}</p>
                </a>
                <a href='/legal/disclamer' className='disc'>
                    <h2>{t('Legal.Disclamer.title')}</h2>
                    <p>{t('Legal.Disclamer.description')}</p>
                </a>
            </div>
        </main>
    )

}