import { RiArrowDownSLine } from "react-icons/ri";
import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PopUp from 'components/PopUp';
import { useDocTitle } from '../../hooks/useDocumentVar';
import './styles.css';
import { contacts } from 'data/consts';
import { useContact } from 'hooks/useContact';
import CustomMetaTags from 'components/CustomMetaTags';
import { Hero } from 'components/Hero';

export default function ContactPage() {
	const { t } = useTranslation();
	useDocTitle(t('NavBar.contactPage') + " - ROOTKey")
	const { changeContact, contact, sendEmail, newsletterSubscribe } = useContact();
	const [popUpAnimation, setPopUpAnimaton] = useState<boolean>(false)
	const [newsletterSubscription, setNewsletterSubscription] = useState<boolean>(false);
	const [msg, setMsg] = useState<string>('');
	const [dropOpen, setDropOpen] = useState<boolean>(false);
	const [popUpForm, setPopUpForm] = useState({
		error: false,
		timer: 6000,
		mensage: 'PopUpMessages.ContactUs.Sucess.message',
		title: 'PopUpMessages.ContactUs.Sucess.title',
	})

	function handleSubmit(event: FormEvent) {
		event.preventDefault();

		try {
			sendEmail(contact)

			if (newsletterSubscription) { newsletterSubscribe(contact.email) }

			setPopUpForm({
				error: false,
				timer: 6000,
				mensage: 'PopUpMessages.ContactUs.Sucess.message',
				title: 'PopUpMessages.ContactUs.Sucess.title',
			})
			setPopUpAnimaton(true);


		} catch (error) {
			setPopUpForm({
				error: true,
				timer: 6000,
				mensage: 'PopUpMessages.ContactUs.Fail.message',
				title: 'PopUpMessages.ContactUs.Fail.title',
			})
			setPopUpAnimaton(true)
		}
	}

	return (
		<main id='Contact'>
			<PopUp
				show={popUpAnimation}
				timer={popUpForm.timer}
				closeAnimation={() => { setPopUpAnimaton(!popUpAnimation) }}
				title={popUpForm.title}
				message={popUpForm.mensage}
				error={popUpForm.error}
			/>
			<CustomMetaTags
				title="Contact ROOTKey - Get in Touch"
				description="Contact ROOTKey for inquiries, support, or collaboration opportunities. We are here to assist you."
				keywords="contact ROOTKey, get in touch, inquiries, support, collaboration, sales"
				ogTitle="Contact ROOTKey - Get in Touch"
				ogDescription="Contact ROOTKey for inquiries, support, or collaboration opportunities. We are here to assist you."
				canonical="https://rootkey.ai/contact"
			/>

			<Hero.Root type='simple'>
				<Hero.Subtitle fade={'fade-right'} text='Contact.hero.description' />
				<Hero.Title fade={'fade-right'} text='Contact.hero.title' />
				<Hero.Img fade={null} imgUrl='https://media.notcybersec.com/website/contact.jpg' />
			</Hero.Root>

			<form onSubmit={handleSubmit}>
				<div className='formHhead'>
					<h3>{t("Contact.formHero.title")}</h3>
					<p>{t("Contact.formHero.description")} </p>
				</div>
				<div className='flex'>
					<div className='column'>
						<label htmlFor='firstName'>{t('Contact.firstName')} *</label>
						<input id='firstName' name='firstName' type="text" required placeholder={t('Contact.firstName')} onChange={event => changeContact({ ...contact, firstName: event.target.value })} value={contact.firstName} />
					</div>
					<div className='column'>
						<label htmlFor='lastName'>{t('Contact.lastName')} *</label>
						<input id='lastName' name='lastName' type="text" required placeholder={t('Contact.lastName')} onChange={event => changeContact({ ...contact, lastName: event.target.value })} value={contact.lastName} />
					</div>
				</div>
				<div className='flex'>
					<div className='column'>
						<label htmlFor='Email'>{t('Contact.email')} *</label>
						<input id='Email' name='Email' type="mail" required placeholder={t('Contact.email')} onChange={event => changeContact({ ...contact, email: event.target.value })} value={contact.email} />
					</div>

					<div className='column'>
						<label htmlFor='companyName'>{t('Contact.companyName')}</label>
						<input id='companyName' name='companyName' type="text" placeholder={t('Contact.companyName')} onChange={event => changeContact({ ...contact, companyName: event.target.value })} value={contact.companyName} />

					</div>
				</div>

				<div className='column'>
					<label htmlFor="Subject">{t('Contact.Subject.title')} *</label>
					<span className={dropOpen ? 'dropDownDiv active' : "dropDownDiv "} onClick={() => setDropOpen(!dropOpen)}>{contact.subject || t('Contact.Subject.Sales')} <RiArrowDownSLine />

						<div className={'dropDown'}>
							<option onClick={() => { changeContact({ ...contact, subject: t('Contact.Subject.Sales') }) }} value={`${t('Contact.Subject.Sales')}`}>{t('Contact.Subject.Sales')}</option>
							<option onClick={() => { changeContact({ ...contact, subject: t('Contact.Subject.Information') }) }} value={`${t('Contact.Subject.Information')}`}>{t('Contact.Subject.Information')}</option>
							<option onClick={() => { changeContact({ ...contact, subject: t('Contact.Subject.Partnerships') }) }} value={`${t('Contact.Subject.Partnerships')}`}>{t('Contact.Subject.Partnerships')}</option>
							<option onClick={() => { changeContact({ ...contact, subject: t('Contact.Subject.FeedBack') }) }} value={`${t('Contact.Subject.FeedBack')}`}>{t('Contact.Subject.FeedBack')}</option>
							<option onClick={() => { changeContact({ ...contact, subject: t('Contact.Subject.Support') }) }} value={`${t('Contact.Subject.Support')}`}>{t('Contact.Subject.Support')}</option>
							<option onClick={() => { changeContact({ ...contact, subject: t('Contact.Subject.Demo') }) }} value={`${t('Contact.Subject.Demo')}`}>{t('Contact.Subject.Demo')}</option>
						</div>
					</span>

				</div>
				<div className='column'>
					<label htmlFor='message'>{t('Contact.message')} *</label>
					<textarea name="message" id="message" rows={6} required onChange={event => { setMsg(event.target.value); changeContact({ ...contact, message: event.target.value }) }} value={msg} />
				</div>
				<div className='column'>
					<div className='flex switch'>
						<label className="switch">
							<input type="checkbox" required />
							<span className="sliderComp"></span>
						</label>
						<span>{t('Contact.data')}<a href="/legal">{t('cookie.legal')}</a></span>
					</div>

					<div className='flex switch'>
						<label className="switch">
							<input type="checkbox" onChange={(event) => setNewsletterSubscription(event.target.checked)} checked={newsletterSubscription} />
							<span className="sliderComp"></span>
						</label>
						<span>{t('Contact.newsletter')}</span>
					</div>
					<button type='submit' className='submit'>{t('Contact.submit')}</button>
				</div>
			</form>


			<div className='contac-info'>

				<div className='column'>
					<h3>{t('Contact.US.headquarters')}</h3>
					<label>{contacts.address}</label>
					<a href={contacts.linkGoogleMaps}>{t('Contact.US.headquartersBtn')}</a>
				</div>

				<div className='column'>
					<h3>{t('Contact.US.email')}</h3>
					<label>{t('Contact.US.emailText')} {contacts.emailSupport}</label>
					<a href={`mailto: ${contacts.emailSupport}`}>{t('Contact.US.emailBtn')}</a>
				</div>

				<div className='column'>
					<h3>{t('Contact.US.phone')}</h3>
					<label>{contacts.tlm}</label>
					<span>{t('Footer.redeFix')}</span>
					<a href={`tel:${contacts.tlm}`}>{t('Contact.US.phoneBtn')}</a>
				</div>
			</div>

			<iframe title="Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3111.612275634344!2d-9.150678924366009!3d38.74965867175596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa05b4720eb69bdf7%3A0x32bc6893e5d93f6f!2sROOTKey!5e0!3m2!1spt-PT!2spt!4v1720636233392!5m2!1spt-PT!2spt" loading="lazy" ></iframe>

		</main>
	)
}
