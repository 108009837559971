import React, { useEffect } from 'react';
import './styles.css';

import { Hero } from 'components/Hero';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { partners } from 'data/partners';
import CustomMetaTags from 'components/CustomMetaTags';

export default function Company() {
	const { t } = useTranslation();

	useEffect(() => {
		AOS.init({ duration: 2000 });
	}, [])

	return (
		<>
			<CustomMetaTags
				title="About ROOTKey - Our Company"
				description="Learn about ROOTKey, a leading cybersecurity and decentralized identity management company. We are committed to data integrity and privacy."
				keywords="ROOTKey, about us, company information, cybersecurity, decentralized identity management, blockchain, nfts, cloud, web3"
				ogTitle="About ROOTKey - Our Company"
				ogDescription="Learn about ROOTKey, a leading cybersecurity and decentralized identity management company. We are committed to data integrity and privacy."
				canonical="https://rootkey.ai/company"
			/>
			<main id='Company'>
				<Hero.Root scrool>
					<Hero.Tags tags={[t('Company.Hero.tag1'), t('Company.Hero.tag2'), t('Company.Hero.tag3')]} />
					<Hero.Title fade={'fade-right'} text='Company.Hero.title' />
					<Hero.Subtitle fade={'fade-right'} text='Company.Hero.description' />
					<Hero.Img fade={null} imgUrl='https://media.notcybersec.com/website/Company/heroCompany.png' />
				</Hero.Root>

				<div className='we'>
					<div className='images'>
						<div className='col'>
							<img key={6} src="https://media.notcybersec.com/website/Company/team/png/img_4.jpg" alt={"team"} />
							<img key={7} src="https://media.notcybersec.com/website/Company/team/png/img_1.jpg" alt={"team"} />
							<img key={4} src="https://media.notcybersec.com/website/Company/team/png/img_3.jpeg" alt={"team"} />
							<img key={5} src="https://media.notcybersec.com/website/Company/team/png/img_5.jpg" alt={"team"} />
							<img key={8} src="https://media.notcybersec.com/website/Company/team/png/img_3.jpg" alt={"team"} />
							<img key={9} src="https://media.notcybersec.com/website/Company/team/png/img_1.jpeg" alt={"team"} />
							<img key={10} src="https://media.notcybersec.com/website/Company/team/png/img_2.jpg" alt={"team"} />
						</div>
						<div className='col'>
							<img key={1} src="https://media.notcybersec.com/website/Company/team/png/img_2.jpeg" alt={"team"} />
							<img key={2} src="https://media.notcybersec.com/website/Company/team/png/img_1.png" alt={"team"} />
							<img key={3} src="https://media.notcybersec.com/website/Company/team/png/carlota.jpg" alt={"team"} />

						</div>
						<div className='col'>
							<img key={6} src="https://media.notcybersec.com/website/Company/team/png/img_4.jpg" alt={"team"} />
							<img key={7} src="https://media.notcybersec.com/website/Company/team/png/img_1.jpg" alt={"team"} />
							<img key={8} src="https://media.notcybersec.com/website/Company/team/png/img_3.jpg" alt={"team"} />
							<img key={9} src="https://media.notcybersec.com/website/Company/team/png/img_1.jpeg" alt={"team"} />
							<img key={10} src="https://media.notcybersec.com/website/Company/team/png/img_2.jpg" alt={"team"} />
						</div>
					</div>
					<div className='text'>
						<div>
							<h2>{t('Company.Section2.title')}</h2>
							<p>{t('Company.Section2.description')}</p>
						</div>
						<div>
							<h2>{t('Company.Section3.title')}</h2>
							<p>{t('Company.Section3.description')}</p>
						</div>
					</div>
					<div className='we box' />
					<div className='we box _2' />
					<div className='we box _3' />
				</div>


				<div className='partners'>
					<h1 className='title'>{t("Company.Partners.title")}</h1>
					<div className='dump'>
						{partners.map((data, index) =>
							<div>
								<a href={data.url} target='_blank' rel="noreferrer" key={index}>
									<img src={data.img} alt={data.alt} />
								</a>
							</div>
						)}
					</div>
				</div>
			</main >
		</>
	)
}