
export const products = [
    {
        title: "Platform.Products.SDS.title",
        slogan: "Platform.Products.SDS.slogan",
        desc: "Platform.Products.SDS.description",
    },
    {
        title: "Platform.Products.Platform.title",
        slogan: "Platform.Products.Platform.slogan",
        desc: "Platform.Products.Platform.description",
    
    },
    {
        title: "Platform.Products.IDM.title",
        slogan: "Platform.Products.IDM.slogan",
        desc: "Platform.Products.IDM.description",
        
    },
    {
        title: "Platform.Products.DAMC.title",
        slogan: "Platform.Products.DAMC.slogan",
        desc: "Platform.Products.DAMC.description",
    },
]