import React from 'react';
import NavBarTlm from './navBarTlm';
import NavBarDesktop from './navBarDesktop';

export default function NavBar() {
	
	if (window.innerWidth < 1200) {
		return <NavBarTlm />
	} else {
		return <NavBarDesktop />

	}
}