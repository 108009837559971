"use client";
import { contacts } from "data/consts";
import { motion, MotionValue } from "framer-motion";
import React from "react";
import { cn } from "utils/cn";

const transition = {
  duration: 0,
  ease: "linear",
};

export const GoogleGeminiEffect = ({
  pathLengths,
  title,
  description,
  className,
}: {
  pathLengths: MotionValue[];
  title?: string;
  description?: string;
  className?: string;
}) => {
  return (
    <div className={cn("sticky top-80 h-[80vh] pt-16 w-screen left-0", className)}>
      <p className="text-lg md:text-7xl font-normal pb-4 text-center bg-clip-text text-transparent bg-gradient-to-b from-neutral-100 to-neutral-300">
        {title || ""}
      </p>
      <p className="text-xs md:text-xl font-normal text-center text-neutral-400 mt-4 max-w-lg mx-auto">
        {description || ""}
      </p>
      <div className="w-full h-[950px] -top-60 md:-top-40 z-50 flex items-center justify-center absolute ">
        <a href={contacts.platform} target="_blank" className="font-bold bg-white rounded-full md:px-4 md:py-2 px-2 py-1 md:mt-24 mt-8 z-30 md:text-base text-black text-xs  w-fit mx-auto ">
          app.rootkey.ai
        </a>
      </div>
      <svg
        width={window.innerWidth}
        height="890"
        viewBox={`0 0 ${window.innerWidth} 890`}
        xmlns="http://www.w3.org/2000/svg"
        className={cn("absolute -top-60 md:-top-40 left-0 scale-125",
          window.innerWidth > 1700 ? "left-1/2 transform -translate-x-1/2 scale-100" : ""
        )}
      >
        <motion.path
          d={window.innerWidth > 1700 ? "M0 663C193.515 663 254.03 666.265 358.77 647C434.245 630 451.535 621 529.675 566C583.87 531.5 606.15 529.5 651.7 523C677.867 519.348 693.93 503.736 715.54 504.236C736.281 504.236 749.033 514.739 777.588 522.749C787.417 525.408 798.266 526.237 808.784 523.019C831.931 515.195 852.722 496.324 874.81 496.735C898.638 496.735 922.415 519.572 947.207 526.769C956.879 528.934 966.502 528.395 974.1 525.965C1000.798 517.115 1016.24 497.106 1040.06 496.735C1058.133 496.47 1070.256 508.859 1093.682 518.515C1110.726 525.171 1132.785 526.815 1149.97 520.069C1167.937 513.049 1185.608 502.706 1203.655 503.736C1229.165 505.171 1244.948 510.562 1257.938 515.673C1266.137 519.76 1274.921 522.792 1284.025 524.954C1324.306 530.611 1339.878 534.118 1376.22 549C1434.075 573.359 1442.725 594.5 1521.2 629C1605.98 670 1767.905 662.5 1915.2 662.5" :
            "M0 663C145.5 663 191 666.265 269 647C326.5 630 339.5 621 397.5 566C439 531.5 455 529.5 490 523C509.664 519.348 521 503.736 538 504.236C553.591 504.236 562.429 514.739 584.66 522.749C592.042 525.408 600.2 526.237 607.356 523.019C624.755 515.195 641.446 496.324 657 496.735C673.408 496.735 693.545 519.572 712.903 526.769C718.727 528.934 725.184 528.395 730.902 525.965C751.726 517.115 764.085 497.106 782 496.735C794.831 496.47 804.103 508.859 822.469 518.515C835.13 525.171 850.214 526.815 862.827 520.069C875.952 513.049 889.748 502.706 903.5 503.736C922.677 505.171 935.293 510.562 945.817 515.673C954.234 519.76 963.095 522.792 972.199 524.954C996.012 530.611 1007.42 534.118 1034 549C1077.5 573.359 1082.5 594.5 1140 629C1206 670 1328.5 662.5 1440 662.5"
          }
          stroke="#FFB7C5"
          strokeWidth="2"
          fill="none"
          initial={{
            pathLength: 0,
          }}
          style={{
            pathLength: pathLengths[0],
          }}
          transition={transition}
        />
        <motion.path
          d={window.innerWidth > 1700 ? "M0 587.5C195.51 587.5 368.41 587.5 412.7 573.5C462.84 563 521.025 543.5 543.44 535C579.72 523.5 567.18 526.235 637.27 515.235C656.02 512.729 695.59 510.435 710.885 512.735C738.485 516.735 740.815 523.235 766.08 523.735C786.44 523.735 820.28 496.735 842.89 497.235C863.762 497.235 880.544 515.052 911.961 524.942C921.371 527.989 933.266 528.738 942.769 525.505C964.499 517.575 984.618 498.33 1006.475 498.742C1030.03 498.742 1053.578 520.623 1078.435 527.654C1088.564 529.841 1100.086 529.246 1109.67 526.991C1134.139 518.893 1150.927 502.112 1172.135 501.742C1180.024 501.597 1194.376 506.047 1208.883 510.911C1240.871 521.707 1273.998 519.639 1307.329 520.063C1361.614 520.686 1382.875 530.797 1410.145 537C1471.987 556.627 1490.045 570.704 1570.065 579.235C1669.475 589.5 1701.07 587 1915.2 588"
            : "M0 587.5C147 587.5 277 587.5 310 573.5C348 563 392.5 543.5 408 535C434 523.5 426 526.235 479 515.235C494 512.729 523 510.435 534.5 512.735C554.5 516.735 555.5 523.235 576 523.735C592 523.735 616 496.735 633 497.235C648.671 497.235 661.31 515.052 684.774 524.942C692.004 527.989 700.2 528.738 707.349 525.505C724.886 517.575 741.932 498.33 757.5 498.742C773.864 498.742 791.711 520.623 810.403 527.654C816.218 529.841 822.661 529.246 828.451 526.991C849.246 518.893 861.599 502.112 879.5 501.742C886.47 501.597 896.865 506.047 907.429 510.911C930.879 521.707 957.139 519.639 982.951 520.063C1020.91 520.686 1037.5 530.797 1056.5 537C1102.24 556.627 1116.5 570.704 1180.5 579.235C1257.5 589.5 1279 587 1440 588"}
          stroke="#FFDDB7"
          strokeWidth="2"
          fill="none"
          initial={{
            pathLength: 0,
          }}
          style={{
            pathLength: pathLengths[1],
          }}
          transition={transition}
        />
        <motion.path
          d={window.innerWidth > 1700 ? "M0 514C196.675 514.333 392.035 513.735 506.265 513.735C540.968 514.94 562.39 515.228 581.372 515.123C636.082 514.803 690.837 506.605 744.165 511.197C751.173 511.706 757.996 512.524 764.75 513.735C783.96 516.433 819.28 521.702 835.575 519.402C862.675 515.402 877.37 499.235 905.065 499.235C934.665 499.235 965.25 529.235 987.86 528.735C1009.709 528.735 1024.471 510.583 1053.688 500.59C1061.658 497.465 1072.413 496.777 1082.196 499.745C1108.91 507.064 1135.055 524.648 1154.78 524.235C1175.315 524.235 1198.087 509.786 1221.014 505.392C1232.41 504.255 1243.69 504.674 1253.453 505.631C1285.101 511.248 1292.205 514.346 1319.035 514.735C1341.379 515.083 1377.545 513.235 1406.815 513.235C1481.285 513.235 1450.865 513.235 1495.08 513.235C1567.075 513.235 1571.057 514.402 1652.47 514.402C1754.325 514.402 1694.085 512.568 1915.2 513.235"
            : "M0 514C147.5 514.333 294.5 513.735 380.5 513.735C405.976 514.94 422.849 515.228 436.37 515.123C477.503 514.803 518.631 506.605 559.508 511.197C564.04 511.706 569.162 512.524 575 513.735C588 516.433 616 521.702 627.5 519.402C647.5 515.402 659 499.235 680.5 499.235C700.5 499.235 725 529.235 742 528.735C757.654 528.735 768.77 510.583 791.793 500.59C798.991 497.465 807.16 496.777 814.423 499.745C832.335 507.064 850.418 524.648 866 524.235C882.791 524.235 902.316 509.786 921.814 505.392C926.856 504.255 932.097 504.674 937.176 505.631C966.993 511.248 970.679 514.346 989.5 514.735C1006.3 515.083 1036.5 513.235 1055.5 513.235C1114.5 513.235 1090.5 513.235 1124 513.235C1177.5 513.235 1178.99 514.402 1241 514.402C1317.5 514.402 1274.5 512.568 1440 513.235"}
          stroke="#B1C5FF"
          strokeWidth="2"
          fill="none"
          initial={{
            pathLength: 0,
          }}
          style={{
            pathLength: pathLengths[2],
          }}
          transition={transition}
        />
        <motion.path
          d={window.innerWidth > 1700 ? "M0 438.5C200.165 438.5 347.13 438.318 430.255 456.5C467.67 464.5 515.602 484.001 563.255 494.5C596.108 501.465 628.96 503.735 647.71 507.735C671.048 512.212 672.985 516.808 696.59 518.735C729.49 521.235 752.221 501.235 777.385 501.235C805.98 501.235 832.58 529.069 855.19 528.569C876.535 528.569 896.866 511.63 927.364 501.972C938.011 499.008 949.937 498.208 957.337 501.617C980.351 509.889 1002.437 529.98 1022.91 529.569C1044.848 529.569 1068.082 507.687 1094.271 500.657C1100.021 498.469 1107.445 499.104 1113.296 501.194C1145.757 508.764 1164.812 523.365 1189.83 523.735C1210.34 524.083 1230.59 504.235 1283.79 506.735C1376.485 506.735 1396.425 492.68 1426.095 481.5C1494.775 457 1519.79 452.871 1576.95 446.5C1670.815 436 1721.02 439 1915.035 439"
            : "M0 438.5C150.5 438.5 261 438.318 323.5 456.5C351 464.5 387.517 484.001 423.5 494.5C447.371 501.465 472 503.735 487 507.735C503.786 512.212 504.5 516.808 523 518.735C547 521.235 564.814 501.235 584.5 501.235C604.5 501.235 626 529.069 643 528.569C658.676 528.569 672.076 511.63 695.751 501.972C703.017 499.008 711.231 498.208 718.298 501.617C735.448 509.889 751.454 529.98 767 529.569C783.364 529.569 801.211 507.687 819.903 500.657C825.718 498.469 832.141 499.104 837.992 501.194C859.178 508.764 873.089 523.365 891 523.735C907.8 524.083 923 504.235 963 506.735C1034.5 506.735 1047.5 492.68 1071 481.5C1122.5 457 1142.23 452.871 1185 446.5C1255.5 436 1294 439 1439.5 439"
          }
          stroke="#4FABFF"
          strokeWidth="2"
          fill="none"
          initial={{
            pathLength: 0,
          }}
          style={{
            pathLength: pathLengths[3],
          }}
          transition={transition}
        />
        <motion.path
          d={window.innerWidth > 1700 ? "M0.5 364C193.84 362.349 259.35 361.5 353.115 378C429.26 391.223 531.509 457.5 546.63 467.5C565.957 478.649 609.707 491.677 661.65 502.699C665.958 503.396 670.255 504.304 674.675 505.374C689.788 511.558 721.529 520.911 735.505 521.236C761.505 521.236 786.8 498.736 814.295 498.736C840.795 498.736 867.825 529.236 890.195 528.736C914.301 528.736 930.707 510.924 961.964 501.036C970.004 497.988 978.865 497.231 986.261 500.579C1014.813 508.857 1042.145 529.148 1062.02 528.736C1080.875 528.736 1102.855 508.68 1124.962 502.179C1130.668 500.169 1136.829 500.802 1142.897 502.718C1178.178 509.338 1192.14 519.866 1222.18 520.236C1243.03 520.583 1246.785 510.236 1290.195 501.736C1347.265 491 1340 493.5 1371.835 480C1424.835 453.5 1430.76 440.442 1511.005 403.5C1581.665 369.5 1710.75 360.374 1915.03 364"
            : "M0.5 364C145.288 362.349 195 361.5 265.5 378C322 391.223 399.182 457.5 411 467.5C424.176 478.649 456.916 491.677 496.259 502.699C498.746 503.396 501.16 504.304 503.511 505.374C517.104 511.558 541.149 520.911 551.5 521.236C571.5 521.236 590 498.736 611.5 498.736C631.5 498.736 652.5 529.236 669.5 528.736C685.171 528.736 697.81 510.924 721.274 501.036C728.505 497.988 736.716 497.231 743.812 500.579C761.362 508.857 778.421 529.148 794 528.736C810.375 528.736 829.35 508.68 848.364 502.179C854.243 500.169 860.624 500.802 866.535 502.718C886.961 509.338 898.141 519.866 916 520.236C932.8 520.583 934.5 510.236 967.5 501.736C1011.5 491 1007.5 493.5 1029.5 480C1069.5 453.5 1072 440.442 1128.5 403.5C1180.5 369.5 1275 360.374 1439 364"}
          stroke="#076EFF"
          strokeWidth="2"
          fill="none"
          initial={{
            pathLength: 0,
          }}
          style={{
            pathLength: pathLengths[4],
          }}
          transition={transition}
        />

        {/* Gaussian blur for the background paths */}

        <path
          d={window.innerWidth > 1700 ? "M0 663C193.515 663 254.03 666.265 358.77 647C434.245 630 451.535 621 529.675 566C583.87 531.5 606.15 529.5 651.7 523C677.867 519.348 693.93 503.736 715.54 504.236C736.281 504.236 749.033 514.739 777.588 522.749C787.417 525.408 798.266 526.237 808.784 523.019C831.931 515.195 852.722 496.324 874.81 496.735C898.638 496.735 922.415 519.572 947.207 526.769C956.879 528.934 966.502 528.395 974.1 525.965C1000.798 517.115 1016.24 497.106 1040.06 496.735C1058.133 496.47 1070.256 508.859 1093.682 518.515C1110.726 525.171 1132.785 526.815 1149.97 520.069C1167.937 513.049 1185.608 502.706 1203.655 503.736C1229.165 505.171 1244.948 510.562 1257.938 515.673C1266.137 519.76 1274.921 522.792 1284.025 524.954C1324.306 530.611 1339.878 534.118 1376.22 549C1434.075 573.359 1442.725 594.5 1521.2 629C1605.98 670 1767.905 662.5 1915.2 662.5" :
            "M0 663C145.5 663 191 666.265 269 647C326.5 630 339.5 621 397.5 566C439 531.5 455 529.5 490 523C509.664 519.348 521 503.736 538 504.236C553.591 504.236 562.429 514.739 584.66 522.749C592.042 525.408 600.2 526.237 607.356 523.019C624.755 515.195 641.446 496.324 657 496.735C673.408 496.735 693.545 519.572 712.903 526.769C718.727 528.934 725.184 528.395 730.902 525.965C751.726 517.115 764.085 497.106 782 496.735C794.831 496.47 804.103 508.859 822.469 518.515C835.13 525.171 850.214 526.815 862.827 520.069C875.952 513.049 889.748 502.706 903.5 503.736C922.677 505.171 935.293 510.562 945.817 515.673C954.234 519.76 963.095 522.792 972.199 524.954C996.012 530.611 1007.42 534.118 1034 549C1077.5 573.359 1082.5 594.5 1140 629C1206 670 1328.5 662.5 1440 662.5"
          }
          stroke="#FFB7C5"
          strokeWidth="2"
          fill="none"
          pathLength={1}
          filter="url(#blurMe)"
        />
        <path
          d={window.innerWidth > 1700 ? "M0 587.5C195.51 587.5 368.41 587.5 412.7 573.5C462.84 563 521.025 543.5 543.44 535C579.72 523.5 567.18 526.235 637.27 515.235C656.02 512.729 695.59 510.435 710.885 512.735C738.485 516.735 740.815 523.235 766.08 523.735C786.44 523.735 820.28 496.735 842.89 497.235C863.762 497.235 880.544 515.052 911.961 524.942C921.371 527.989 933.266 528.738 942.769 525.505C964.499 517.575 984.618 498.33 1006.475 498.742C1030.03 498.742 1053.578 520.623 1078.435 527.654C1088.564 529.841 1100.086 529.246 1109.67 526.991C1134.139 518.893 1150.927 502.112 1172.135 501.742C1180.024 501.597 1194.376 506.047 1208.883 510.911C1240.871 521.707 1273.998 519.639 1307.329 520.063C1361.614 520.686 1382.875 530.797 1410.145 537C1471.987 556.627 1490.045 570.704 1570.065 579.235C1669.475 589.5 1701.07 587 1915.2 588"
            : "M0 587.5C147 587.5 277 587.5 310 573.5C348 563 392.5 543.5 408 535C434 523.5 426 526.235 479 515.235C494 512.729 523 510.435 534.5 512.735C554.5 516.735 555.5 523.235 576 523.735C592 523.735 616 496.735 633 497.235C648.671 497.235 661.31 515.052 684.774 524.942C692.004 527.989 700.2 528.738 707.349 525.505C724.886 517.575 741.932 498.33 757.5 498.742C773.864 498.742 791.711 520.623 810.403 527.654C816.218 529.841 822.661 529.246 828.451 526.991C849.246 518.893 861.599 502.112 879.5 501.742C886.47 501.597 896.865 506.047 907.429 510.911C930.879 521.707 957.139 519.639 982.951 520.063C1020.91 520.686 1037.5 530.797 1056.5 537C1102.24 556.627 1116.5 570.704 1180.5 579.235C1257.5 589.5 1279 587 1440 588"}
          stroke="#FFDDB7"
          strokeWidth="2"
          fill="none"
          pathLength={1}
          filter="url(#blurMe)"
        />
        <path
          d={window.innerWidth > 1700 ? "M0 514C196.675 514.333 392.035 513.735 506.265 513.735C540.968 514.94 562.39 515.228 581.372 515.123C636.082 514.803 690.837 506.605 744.165 511.197C751.173 511.706 757.996 512.524 764.75 513.735C783.96 516.433 819.28 521.702 835.575 519.402C862.675 515.402 877.37 499.235 905.065 499.235C934.665 499.235 965.25 529.235 987.86 528.735C1009.709 528.735 1024.471 510.583 1053.688 500.59C1061.658 497.465 1072.413 496.777 1082.196 499.745C1108.91 507.064 1135.055 524.648 1154.78 524.235C1175.315 524.235 1198.087 509.786 1221.014 505.392C1232.41 504.255 1243.69 504.674 1253.453 505.631C1285.101 511.248 1292.205 514.346 1319.035 514.735C1341.379 515.083 1377.545 513.235 1406.815 513.235C1481.285 513.235 1450.865 513.235 1495.08 513.235C1567.075 513.235 1571.057 514.402 1652.47 514.402C1754.325 514.402 1694.085 512.568 1915.2 513.235"
            : "M0 514C147.5 514.333 294.5 513.735 380.5 513.735C405.976 514.94 422.849 515.228 436.37 515.123C477.503 514.803 518.631 506.605 559.508 511.197C564.04 511.706 569.162 512.524 575 513.735C588 516.433 616 521.702 627.5 519.402C647.5 515.402 659 499.235 680.5 499.235C700.5 499.235 725 529.235 742 528.735C757.654 528.735 768.77 510.583 791.793 500.59C798.991 497.465 807.16 496.777 814.423 499.745C832.335 507.064 850.418 524.648 866 524.235C882.791 524.235 902.316 509.786 921.814 505.392C926.856 504.255 932.097 504.674 937.176 505.631C966.993 511.248 970.679 514.346 989.5 514.735C1006.3 515.083 1036.5 513.235 1055.5 513.235C1114.5 513.235 1090.5 513.235 1124 513.235C1177.5 513.235 1178.99 514.402 1241 514.402C1317.5 514.402 1274.5 512.568 1440 513.235"}
          stroke="#B1C5FF"
          strokeWidth="2"
          fill="none"
          pathLength={1}
          filter="url(#blurMe)"
        />
        <path
          d={window.innerWidth > 1700 ? "M0 438.5C200.165 438.5 347.13 438.318 430.255 456.5C467.67 464.5 515.602 484.001 563.255 494.5C596.108 501.465 628.96 503.735 647.71 507.735C671.048 512.212 672.985 516.808 696.59 518.735C729.49 521.235 752.221 501.235 777.385 501.235C805.98 501.235 832.58 529.069 855.19 528.569C876.535 528.569 896.866 511.63 927.364 501.972C938.011 499.008 949.937 498.208 957.337 501.617C980.351 509.889 1002.437 529.98 1022.91 529.569C1044.848 529.569 1068.082 507.687 1094.271 500.657C1100.021 498.469 1107.445 499.104 1113.296 501.194C1145.757 508.764 1164.812 523.365 1189.83 523.735C1210.34 524.083 1230.59 504.235 1283.79 506.735C1376.485 506.735 1396.425 492.68 1426.095 481.5C1494.775 457 1519.79 452.871 1576.95 446.5C1670.815 436 1721.02 439 1915.035 439"
            : "M0 438.5C150.5 438.5 261 438.318 323.5 456.5C351 464.5 387.517 484.001 423.5 494.5C447.371 501.465 472 503.735 487 507.735C503.786 512.212 504.5 516.808 523 518.735C547 521.235 564.814 501.235 584.5 501.235C604.5 501.235 626 529.069 643 528.569C658.676 528.569 672.076 511.63 695.751 501.972C703.017 499.008 711.231 498.208 718.298 501.617C735.448 509.889 751.454 529.98 767 529.569C783.364 529.569 801.211 507.687 819.903 500.657C825.718 498.469 832.141 499.104 837.992 501.194C859.178 508.764 873.089 523.365 891 523.735C907.8 524.083 923 504.235 963 506.735C1034.5 506.735 1047.5 492.68 1071 481.5C1122.5 457 1142.23 452.871 1185 446.5C1255.5 436 1294 439 1439.5 439"
          }
          stroke="#4FABFF"
          strokeWidth="2"
          fill="none"
          pathLength={1}
          filter="url(#blurMe)"
        />
        <path
          d={window.innerWidth > 1700 ? "M0.5 364C193.84 362.349 259.35 361.5 353.115 378C429.26 391.223 531.509 457.5 546.63 467.5C565.957 478.649 609.707 491.677 661.65 502.699C665.958 503.396 670.255 504.304 674.675 505.374C689.788 511.558 721.529 520.911 735.505 521.236C761.505 521.236 786.8 498.736 814.295 498.736C840.795 498.736 867.825 529.236 890.195 528.736C914.301 528.736 930.707 510.924 961.964 501.036C970.004 497.988 978.865 497.231 986.261 500.579C1014.813 508.857 1042.145 529.148 1062.02 528.736C1080.875 528.736 1102.855 508.68 1124.962 502.179C1130.668 500.169 1136.829 500.802 1142.897 502.718C1178.178 509.338 1192.14 519.866 1222.18 520.236C1243.03 520.583 1246.785 510.236 1290.195 501.736C1347.265 491 1340 493.5 1371.835 480C1424.835 453.5 1430.76 440.442 1511.005 403.5C1581.665 369.5 1710.75 360.374 1915.03 364"
            : "M0.5 364C145.288 362.349 195 361.5 265.5 378C322 391.223 399.182 457.5 411 467.5C424.176 478.649 456.916 491.677 496.259 502.699C498.746 503.396 501.16 504.304 503.511 505.374C517.104 511.558 541.149 520.911 551.5 521.236C571.5 521.236 590 498.736 611.5 498.736C631.5 498.736 652.5 529.236 669.5 528.736C685.171 528.736 697.81 510.924 721.274 501.036C728.505 497.988 736.716 497.231 743.812 500.579C761.362 508.857 778.421 529.148 794 528.736C810.375 528.736 829.35 508.68 848.364 502.179C854.243 500.169 860.624 500.802 866.535 502.718C886.961 509.338 898.141 519.866 916 520.236C932.8 520.583 934.5 510.236 967.5 501.736C1011.5 491 1007.5 493.5 1029.5 480C1069.5 453.5 1072 440.442 1128.5 403.5C1180.5 369.5 1275 360.374 1439 364"}

          stroke="#076EFF"
          strokeWidth="2"
          fill="none"
          pathLength={1}
          filter="url(#blurMe)"
        />

        <defs>
          <filter id="blurMe">
            <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
          </filter>
        </defs>
      </svg>
    </div>
  );
};
