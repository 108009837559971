import React from 'react';
import Routes from './routes';
import './App.css';
import './styles/Global.css'
import { JobProvider } from './context/jobContext';
import { ContactProvider } from 'context/contactContext';

function App() {

  window.addEventListener("blur", () => {
    document.title = "ROOTKey";
  })

  return (
    <ContactProvider>
      <JobProvider>
        <Routes />
      </JobProvider>
    </ContactProvider>
  );
}

export default App;
